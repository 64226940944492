import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { drawerItemPaths } from '../constants/drawerItems';

const SiteTitleChanger = () => {
  const location = useLocation();

  const publicRoutes = [
    { path: '/webinar', label: 'Webinar' },
    { path: '/signin', label: 'Log In' },
    { path: '/dashboard/signin', label: 'Sign Up' },
    { path: '/login', label: 'Log In' },
    { path: '/payment-status', label: 'Payment Status' },
    { path: '/verify-email', label: 'Verify Email' },
    { path: '/find-username', label: 'Find Username' },
    { path: '/new-password', label: 'New Password' },
    { path: '/token-expired', label: 'Token Expired' },
    { path: '/signup/form', label: 'Sign Up' },
    { path: '/thank-you', label: 'Sign In' },
    { path: '/user-exists', label: 'Sign In' },
    { path: '/dashboard/registration/new', label: 'Registration' },
    { path: '/dashboard/registration/newRegister', label: 'Registration' },
    { path: '/dashboard/enroll-now/pay', label: 'Enroll Now' },
    { path: '/dashboard/enroll-now/success', label: 'Enroll Success' },
    { path: '/renew-account', label: 'Renew Account' },
    { path: '/admin/signin', label: 'Sign In' },
    { path: '/payments/final', label: 'Payments' },
  ];
  
  drawerItemPaths.push(...publicRoutes);

  useEffect(() => {
    const pageItem = drawerItemPaths.find((item) => item.path === location.pathname);
    document.title = `MemeGames.ai${pageItem ? ` | ${pageItem.label}` : ''}`;
  }, [document.location, location]);
};

export default SiteTitleChanger;
