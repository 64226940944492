import {
  Typography,
} from '@mui/material';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthLayout from '../../../components/AuthLayout';
import { getFromSession } from '../../../utils/session';
import { useNonAuthApi } from '../../../utils/api';

export default function VerifyEmailConfirm() {
  const signupUser = useMemo(() => getFromSession('signupUser'), []);
  const { t } = useTranslation();
  const nav = useNavigate();
  const { nonAuthPost } = useNonAuthApi();

  useEffect(() => {
    console.log({ signupUser });
    if (signupUser) {
      if (signupUser?.emailVerified) {
        const redirected = signupUser.signupStep === 'payment' ? '/dashboard/enroll-now/pay' : '/';

        nav(redirected);
      }
    } else {
      nav('/');
    }
  }, [signupUser]);

  const resendEmail = async () => {
    try {
      // test
      const res = await nonAuthPost('/auth/resend-tokenn', { data: { email: signupUser.Email } });
      toast.success('Email resent successfuly!');
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (

    <AuthLayout>

      <Typography sx={{
        color: 'white', mb: 2, textAlign: 'center', fontSize: '18px',
      }}
      >
        {t(' We sent a verification email to ')}

        <span style={{ color: 'var(--primary-color)' }}>
          {' '}
          {signupUser?.Email}
          {' '}
        </span>
      </Typography>
      <Typography sx={{
        color: 'white', mb: 2, textAlign: 'center', fontSize: '18px',
      }}
      >
        {t('Please check it for further instructions.')}
      </Typography>
      <Typography sx={{
        color: 'white', mb: 2, textAlign: 'center', fontSize: '18px',
      }}
      >
        {t('Email not found?')}

      </Typography>
      <Typography
        onClick={() => {
          resendEmail();
        }}
        sx={{
          color: 'var(--primary-color)',
          textAlign: 'center',
          fontSize: '18px',
          cursor: 'pointer',
          ':hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {t(' Resend Email!')}
      </Typography>

    </AuthLayout>

  );
}
