/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { Context } from '../context';
import Header from './memeGame/Header';
import VoteModal from './memeGame/VoteModal';
import Icon from '../../../components/Icon';
import MultiplierModal from './memeGame/MultiplierModal';
import { tabTypes } from '../context/helper';
import TodayOtherCoins from './memeGame/TodayOtherConts';
import YesterdayOtherCoins from './memeGame/YesterdayOtherConts';
import AdminModal from './AdminModal';
import TodayGameMatchUp from './memeGame/TodayGameMatchUp';
import YesterdayGameMatchUp from './memeGame/YesterdayGameMatchUp';
import ComponentLoader from '../../../components/ComponentLoader';
// import { SocketContext } from '../../../utils/store/socket';

export default function GamesPool() {
  const {
    loadingCoins, yesterdayMemeCoins, updateGeckoData,
    memeCoinsList, openMemeConfirmModal, todayGame,
    userWonYesterday, leaderboardRank,
    activeTab, user,
  } = useContext(Context);

  const [multiplierModalType, setMultiplierModalType] = useState(null);
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [adminModalGame, setAdminModalGame] = useState(null);
  const [width, setWidth] = useState(0);
  const isMobile = useMemo(() => width <= 850, [width]);

  const setWidthCallback = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    setWidthCallback();
    window.addEventListener('resize', setWidthCallback);
    return () => window.removeEventListener('resize', setWidthCallback);
  }, []);

  const iconColor = userWonYesterday ? '#793300' : '#DAAB74';

  const onCloseMultiplierModal = () => setMultiplierModalType(null);

  useEffect(() => {
    updateGeckoData();
  }, []);

  const renderCont = ({
    type, value, label, color,
  }) => (
    <div className="multiplier-cont">
      <div className="flex">
        <h2>{value}</h2>
        {type !== 'lastPoints' ? (
          <div className="ml-1 cursor-pointer" onClick={() => setMultiplierModalType(type)}>
            <Icon name="SquaredQuestionmark" width={16} height={16} color={color || iconColor} />
          </div>
        ) : null}
      </div>
      <h5>{label}</h5>
    </div>
  );

  const renderOtherConts = (game, currentVoteIndex, setCurrentVoteIndex) => {
    if (loadingCoins) {
      return null;
    }

    if (activeTab === tabTypes.TODAY) {
      const coins = memeCoinsList.find((c) => c.gameId === (game.id))?.coins || [];
      return (
        <TodayOtherCoins
          game={game}
          user={user}
          coins={coins}
          leaderboardRank={leaderboardRank}
          isMobile={isMobile}
          currentVoteIndex={currentVoteIndex}
          setCurrentVoteIndex={setCurrentVoteIndex}
        />
      );
    }

    const coins = yesterdayMemeCoins.find((c) => c.gameId === game.scheduleGameId.id)?.coins || [];
    return (
      <YesterdayOtherCoins
        game={game}
        user={user}
        coins={coins}
        leaderboardRank={leaderboardRank}
        isMobile={isMobile}
        currentVoteIndex={currentVoteIndex}
        setCurrentVoteIndex={setCurrentVoteIndex}
      />
    );
  };

  return (
    <div className="meme-coins-page">
      <Header />

      {loadingCoins ? (<ComponentLoader />) : (
        <div className="flex gap-2 flex-column meme-coins">
          {activeTab === tabTypes.TODAY
            && todayGame.length > 0
            && todayGame.map((game, idx) => (
              <TodayGameMatchUp
                key={idx}
                setAdminModalGame={setAdminModalGame}
                setOpenAdminModal={setOpenAdminModal}
                renderOtherConts={renderOtherConts}
                isMobile={isMobile}
                gameMatch={game}
              />
            ))}

          {activeTab === tabTypes.YESTERDAY
            && yesterdayMemeCoins.length > 0
            && yesterdayMemeCoins.map((game, idx) => (
              <YesterdayGameMatchUp
                key={idx}
                setAdminModalGame={setAdminModalGame}
                setOpenAdminModal={setOpenAdminModal}
                renderOtherConts={renderOtherConts}
                isMobile={isMobile}
                gameMatch={game}
              />
            ))}

          {openMemeConfirmModal && (
            <VoteModal
              gameId={openMemeConfirmModal.gameId}
              renderCont={renderCont}
            />
          )}

          {openAdminModal && (
            <AdminModal
              openAdminModal={openAdminModal}
              onClose={() => setOpenAdminModal(false)}
              game={adminModalGame}
            />
          )}

          {multiplierModalType && (
            <MultiplierModal
              onClose={onCloseMultiplierModal}
              multiplierModalType={multiplierModalType}
            />
          )}
        </div>
      )}
    </div>
  );
}
