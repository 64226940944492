import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Button from '../Button';
import Modal from '../Modal';
import { renderNumber } from '../../utils/string';
import useApi from '../../utils/api';
import { useAuthentication } from '../../utils/store';

const RedeemButton = ({
  totalTokens, gameId, coin, buttonSize = 'sm', buttonText = null,
}) => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const [openInstructionsModal, setOpenInstructionsModal] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [openInactiveMessageModal, setOpenInactiveMessageModal] = useState(false);
  const [openRedeemModal, setOpenRedeemModal] = useState(false);
  const [timer, setTimer] = useState(null);
  const { authPost, authGet } = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthentication();
  const [solanaWallet, setSolanaWallet] = useState(null);
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const minTokens = Number(process.env.REACT_APP_REDEMPTION_MIN_TOKENS || 1000);

  const getSolanaWallet = useCallback(async () => {
    const _data = await authGet('/wallets');
    const _solana = _data?.find((i) => i.type === 'solana');
    setSolanaWallet(_solana || null);
  }, [authGet]);

  const handleOpenRedeemModal = () => {
    setOpenRedeemModal(true);
    getSolanaWallet();
  };

  const handleCloseRedeemModal = () => {
    clearInterval(timer);
    setTimer(null);
    setOpenRedeemModal(false);
  };

  const handleRedeemModalSubmit = async () => {
    if (!processing) {
      setProcessing(true);

      const redeemData = {
        tokenAmount: minTokens,
        memeCoinId: coin?.id,
        scheduleGameId: gameId,
        solanaWalletKey: solanaWallet?.publicKey,
      };

      try {
        await authPost('/redemptions/redeem-tokens', { data: redeemData });

        navigate('/redeem-token', { state: { reloadWallet: true }, replace: true });

        toast.success(t(`Thank you! You have redeemed ${renderNumber(minTokens)}. Your coins will be delivered to your Solana Wallet within 5 business days.`));
      } catch (err) {
        console.log({ err });
        toast.error(t(err));
      }

      setProcessing(false);

      handleCloseRedeemModal();
    }
  };

  const handleGoToWalletsButton = () => {
    const walletUrl = user.isAffiliate ? '/ambassador-wallets' : '/gamer-wallets';

    navigate(walletUrl);
  };

  const handleOpenPopUpModal = () => {
    setPopupIsOpen(true);
  };

  const handleClosePopUpModal = () => {
    setPopupIsOpen(false);

    if (!totalTokens >= minTokens) {
      setOpenInactiveMessageModal(true);
      return;
    }

    if (totalTokens >= minTokens) {
      const redeemUrl = '/redeem-token';
      if (location.pathname === redeemUrl) {
        handleOpenRedeemModal();

        setProcessing(true);

        setProcessing(false);
      } else {
        navigate(redeemUrl, { state: { openInstructions: true } });
      }
    } else {
      setOpenMessageModal(true);
    }
  };

  const handleOnClick = async () => {
    handleOpenPopUpModal();
  };

  useEffect(() => {
    if (location.state?.openInstructions) {
      location.state.openInstructions = false;
      setOpenInstructionsModal(true);
    }
  });

  return (
    <>
      <Button
        onClick={handleOnClick}
        size={buttonSize}
        bordered={!(totalTokens >= minTokens)}
      >
        <span className="no-wrap">{buttonText && location.pathname === '/redeem-token' ? t(buttonText) : t('Redeem')}</span>
      </Button>
      <Modal
        title={t('Redeem Tokens')}
        width="550px"
        open={popupIsOpen}
        handleClose={() => { handleClosePopUpModal(); }}
      >
        <p className="mb-8 text-center">
          Redemptions of your MG Tokens can be used to buy new gamer packs and Meme Coins on the
          Solana blockchain! Packs start at 1,350 Tokens and Meme Coins start at 2,000 Tokens.
          Redeem for any Meme Coin you have won with in any of your previous games. We will send
          you $200 worth of that selected Meme Coin within 5 business days, the price of the Meme
          Coin at the time it is processed. Meme Coins are transferred to the Solana
          wallet you have set up in your Gamer Wallet.
        </p>
        <Button onClick={handleClosePopUpModal} bordered>Close to Continue</Button>
      </Modal>
      <Modal
        title={t('Redeem Tokens')}
        width="550px"
        open={openInstructionsModal}
        handleClose={() => { setOpenInstructionsModal(false); }}
      >
        <p className="mb-4 text-center">Choose a Meme Coin from any of your winning games to receive $200 USD in those tokens at the time of redemption to your attached Solana Wallet!</p>
      </Modal>
      <Modal
        title={t('Not enough Tokens')}
        width="550px"
        open={openMessageModal}
        handleClose={() => { setOpenMessageModal(false); }}
      >
        <p className="mb-4 text-center">{`Minimum redemption is ${renderNumber(minTokens)} Tokens - keep playing!`}</p>
      </Modal>
      <Modal
        title={t('User Account')}
        width="550px"
        open={openInactiveMessageModal}
        handleClose={() => { setOpenInactiveMessageModal(false); }}
      >
        <p className="mb-4 text-center">
          You must be an active gamer to redeem tokens! An active gamer is one that has activated a game pack and played a memegame within the last 30 days.
        </p>
      </Modal>
      <Modal
        title={t('Redeem Tokens')}
        width="550px"
        open={openRedeemModal}
        handleClose={() => { handleCloseRedeemModal(); }}
      >
        {processing && (
          <div
            className="flex justify-center"
            style={{
              position: 'absolute', width: '100%', left: 0, top: '50px',
            }}
          >
            <CircularProgress />
          </div>
        )}
        <div className="redeem-coin text-center mb-4">
          <div className="redeem-coin-name">
            <strong>{coin?.name}</strong>
          </div>
          <div className="redeem-coin-image">
            <img src={coin?.image} height={90} width={90} alt={coin?.name} />
          </div>
          <div className="redeem-coin-reward">
            <span>{`Redeem for ${coin?.name}`}</span>
          </div>
        </div>
        <div className="divider" />
        {(totalTokens > minTokens) ? (
          <p className="mb-4 text-center">{`Redeem ${renderNumber(minTokens)} from your ${renderNumber(totalTokens)} Tokens`}</p>
        ) : (
          <p className="mb-4 text-center">{`Redeem your ${renderNumber(totalTokens)} Tokens`}</p>
        )}
        {solanaWallet ? (
          <Button onClick={handleRedeemModalSubmit} disabled={processing}>Redeem</Button>
        ) : (
          <>
            <Button onClick={handleGoToWalletsButton} bordered>Connect Wallet</Button>
            <Typography textAlign="center" marginTop="8px" fontSize="14px">You need to connect your Solana Wallet to the system</Typography>
          </>
        )}
      </Modal>
    </>
  );
};

export default RedeemButton;
