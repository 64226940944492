/* eslint-disable no-nested-ternary */
import { Typography } from '@mui/material';
import { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import LockIcon from '@mui/icons-material/Lock';
import { Context } from '../../../context';
import Button from '../../../../../components/Button';
import Modal from '../../../../../components/Modal';
import useApi from '../../../../../utils/api';
import ComponentLoader from '../../../../../components/ComponentLoader';
import { renderNumber } from '../../../../../utils/number';
import { SocketContext } from '../../../../../utils/store/socket';

export default function LockButton({ game, currentVote }) {
  const locked = game?.statistics?.locked;
  const myPercentage = locked ? game?.statistics?.lockChange || 0 : game?.statistics?.change || 0;

  const { authPost } = useApi();
  const socket = useContext(SocketContext);
  const {
    user, chosenMemeCoin: chosenMemeCoins, refreshMemeCoins,
  } = useContext(Context);

  const [lockModalVisible, setLockModalVisible] = useState(false);
  const [loadingLock, setLoadingLock] = useState(false);
  const [myPercentChange, setMyPercentChange] = useState(myPercentage || 0);

  const chosenMemeCoin = chosenMemeCoins.find((c) => c.gameId === game.id);

  const onLockCoin = async () => {
    try {
      setLoadingLock(true);
      await authPost('/schedule-game/lock-game', {
        data: {
          memeCoinsId: chosenMemeCoin.id,
          gameId: game.id,
          voteId: currentVote?._id,
        },
      });
      refreshMemeCoins();
      setLockModalVisible(false);
    } catch (err) {
      toast.error(err);
      console.log(err);
    }
    setLoadingLock(false);
  };

  if (!chosenMemeCoin) {
    return null;
  }

  useEffect(() => {
    if (game) {
      socket.on(`game:${game.id}:user:${user.SID}:lock-btn`, (data) => {
        setMyPercentChange(data);
      });
    }
    return () => {
      socket.off(`game:${game.id}:user:${user.SID}:lock-btn`);
    };
  }, [socket, myPercentChange, game, user.SID]);

  return !currentVote?.locked ? (
    <>
      <Button
        id={currentVote?._id}
        type="submit"
        className={clsx('w-full mt-4 mb-4')}
        onClick={() => {
          setLockModalVisible(true);
        }}
      >
        {`Lock In (${renderNumber(currentVote?.stats?.change?.toFixed(2))}%)`}
      </Button>

      <Modal
        title="Lock in Current Score"
        open={lockModalVisible}
        handleClose={() => setLockModalVisible(false)}
        width="500px"
      >
        {!loadingLock ? (
          <>
            <Typography style={{ textAlign: 'center' }}>
              Are you sure you want to lock in current score?
            </Typography>

            <div className="flex gap-2 mt-4">
              <Button bordered className="flex-1" onClick={() => setLockModalVisible(false)}>
                No
              </Button>

              <Button className="flex-1" onClick={onLockCoin}>
                Yes
              </Button>
            </div>
          </>
        ) : (
          <ComponentLoader />
        )}
      </Modal>
    </>
  ) : (
    <div className="locked-price-cont mt-5 mb-5">
      <span style={{ marginRight: '5px', position: 'relative', top: '4px' }}>
        <LockIcon />
      </span>
      <span>
        {`Game Locked (${currentVote?.stats?.change?.toFixed(2) || renderNumber(myPercentage.toFixed(2))}%)`}
      </span>
    </div>
  );
}
