import { Grid, Typography } from '@mui/material';
import { useState } from 'react';

const SolanaTokenRow = ({ token }) => {
  const [rowDisplay, setRowDisplay] = useState('flex');

  return (
    <Grid
      container
      paddingY="12px"
      borderBottom="1px solid rgba(255,255,255, 0.1)"
      display={rowDisplay}
    >
      <Grid item xs={6}>
        <Typography
          alignItems="center"
          display="flex"
          fontSize="14px"
          gap="8px"
          sx={{ fontSize: { md: '16px' } }}
        >
          {!!token.logoUrl && (
            <picture className="wallet-token-logo">
              <img alt="logo" src={token.logoUrl} height="32px" onError={() => setRowDisplay('none')} />
            </picture>
          )}
          {token.name}
        </Typography>
      </Grid>
      <Grid item xs={6} display="flex" alignItems="center">
        <Typography fontSize="14px" sx={{ fontSize: { md: '16px' } }}>
          {`${Number(token.balance).toLocaleString('en-us')} `}
          {token.symbol}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SolanaTokenRow;
