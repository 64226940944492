import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import Table from '../../../../components/Table';

const InvoiceGroups = ({ items, salesColumnsConfiguration, loadedSalesData }) => (
  <div className="sales-group">
    {items.result.map((item, idx) => (

      <div key={idx}>
        <div className="game-group-header">
          <Typography sx={{
            fontSize: { xs: '16px' }, fontWeight: 'bold', display: 'flex', justifyContent: 'center',
          }}
          >
            {dayjs(item._id).format('MMM DD, YYYY')}
          </Typography>
        </div>
        <div className="sales-group-games">
          <Grid
            maxWidth="100%"
            size="xsm"
            item
            sx={{
              mb: { lg: '8px' }, padding: { xs: 2, lg: 4 },
            }}
            className="game-container"
            display="flex"
            flexDirection="column"
          >
            <Grid
              maxWidth="100%"
              size="xsm"
              className="game-container-header"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              padding="0"
            >
              <Typography sx={{
                fontSize: { xs: '16px' },
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'left',
              }}
              >
                <span style={{ padding: '10px 0' }}>{`Sales: ${item.count}`}</span>
                <span>{`Total: $${item?.total?.toLocaleString('en')}`}</span>

              </Typography>

              <Table
                loading={!loadedSalesData}
                columns={salesColumnsConfiguration}
                rows={item?.invoices || []}
                className="table"
                showPagination
              />

            </Grid>
          </Grid>
        </div>
      </div>
    ))}
  </div>
);

export default InvoiceGroups;
