import { createContext } from 'react';
import useBoosterLeadAdminApi from './api';

export const BoosterLeadAdminContext = createContext({
  t: () => {},
  user: {},

  fetchAllTurnsData: () => {},
  turnsDataColumnsConfiguration: [],
  turnsData: [],
  loadedTurnsData: false,

  openAddAmbassadorModal: false,
  handleAddAmbassadorModalSubmit: () => {},
  handleCloseAmbassadorModal: () => {},

  fetchAmbassadors: () => {},
  clearAmbassadorsData: () => {},
  setOpenAddAmbassadorModal: () => {},
  handleChangeSelectedAmbassador: () => {},
  selectedAmbassador: null,
  ambassadorsData: [],
  loadedAmbassadorsData: false,
});

export const BoosterLeadAdminProvider = ({ children }) => {
  const value = useBoosterLeadAdminApi();

  return (
    <BoosterLeadAdminContext.Provider value={value}>
      {children}
    </BoosterLeadAdminContext.Provider>
  );
};
