import { api } from '.';

const fetchMintsByAddresses = async (addresses) => {
  const url = 'https://token-list-api.solana.cloud/v1/mints?chainId=101';

  try {
    const result = await api.post(url, { addresses });

    return result.data;
  } catch (error) {
    console.log('error...', error);
  }

  return null;
};

export default fetchMintsByAddresses;
