import { createContext } from 'react';
import useHomeApi from './hook';
import { tabTypes } from './helper';

export const Context = createContext({
  rank: [],
  userWonYesterday: false,
  loadingCoins: false,
  rankColumns: [],
  rankProgression: [],
  user: {},
  events: [],
  eventsIndex: 1,
  setEventsIndex: () => {},
  eventChange: () => {},
  loadingUpdates: false,
  updateColumns: [],
  updatesData: [],
  totalPages: 0,
  updatesPage: 0,
  fetchUpdates: () => {},
  affiliateUpdatesColumns: [],
  t: () => {},
  sales: [],
  salesLoading: false,
  salesColumns: [],
  defaultWallet: {},
  coopWallet: {},
  onTrecGlobalClick: () => {},
  eventsLoading: false,
  volumes: [],
  volumesLoading: false,
  role: '',
  clickedAIButton: 0,
  setClickedAIButton: () => {},

  askAIModal: false,
  setAskAIModal: () => {},
  handleAskAIModalClose: () => {},
  setAiAnswer: () => {},
  aiAnswer: '',
  aiQuestion: '',
  setAiQuestion: () => {},
  aiAnswerLoading: false,
  setAiAnswerLoading: () => {},
  askAiSubmit: () => {},
  aiConversation: [],

  cryptoTimeSpan: '24H',
  setCryptoTimeSpan: () => {},

  newsTab: 0,
  changeNewsTab: () => {},
  changeNewsTabTab: () => {},
  newsTabTab: 0,
  news: [],

  expert: 0,
  changeExpert: () => {},

  showProduct: false,

  chosenMemeCoin: null,
  onChooseMemeCoin: () => {},
  chosenYesterdayMemeCoin: null,
  setYesterdayChosenMemeCoin: () => {},

  setChosenMemeCoin: () => {},
  onVoteMemeCoin: () => {},
  memeCoinsList: [],
  openMemeConfirmModal: false,
  setOpenMemeConfirmModal: () => {},
  yesterdayMemeCoins: [],
  refreshMemeCoins: () => {},

  todayGame: null,
  updateGeckoData: () => {},

  points: {},
  tokens: 0,
  gamingFunds: 0,
  streakMultiplier: 1,
  gamingFundsLoading: true,

  gamerWallet: {},

  personalVolume: 0,
  gamersVolume: 0,
  qualificationPercent: 0,

  activeTab: tabTypes.TODAY,
  setActiveTab: () => {},

  activatedPassesToDisplay: [],

  selectedGameVote: {},
  setSelectedGameVote: () => {},
});

export const Provider = ({ children }) => {
  const value = useHomeApi();
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
