/* eslint-disable max-len */
import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MemeGamerChallengePage = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" className="memegamer-rules-page">
      <Typography
        sx={{
          color: 'var(--primary-color)',
          fontSize: '18px',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
        }}
        variant="h1"
      >
        MEMEGAMER CHALLENGE RULES
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('1. Packages are only available to users that are both gamers and ambassadors')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('2. Gamers purchase 1 of 5 special gamer packages  ranging from $1,000 to $6,000 in your Gamer dashboard under coin packs')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('3. For each day a gamer plays they will receive a limited introductory volume bonus in their ambassador team 1 and team 2 network ranging from $25,000 to $150,000.')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('4. The promo time frame is for 30 days from the date of the special gamer package purchase.')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('5. First date of purchase is Nov 1 and the last day to purchase is Nov 30.')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('6. You may purchase one of each level package during the promotional timeframe. The bonuses are not stacked and only the largest bonus would be applied.')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('7. Each package is activated immediately upon purchase. You cannot transfer or save these packages in your “Coin Packs” section of the game.')}
      </Typography>

      <Grid sx={{ my: 3 }}>
        <Typography>
          {t('Package Options: ')}
        </Typography>
        <ul
          style={{
            marginLeft: '40px',
            color: 'white',
            listStyleType: 'lower-alpha',
          }}
        >
          <li>
            {t('Level I: $1,000 and they receive 1,000 coins to play. Their $1,000 volume counts to upline ambassador sponsors. Each day they play the 100, 25 and 10 coin games a $25,000 volume bonus will be awarded in their ambassador team 1 and team 2.')}
          </li>

          <li>
            {t('Level II : $,2000 and they receive 2000 coins to play. Their $2000 volume counts to upline ambassador sponsors. Each day they play the 100, 25 and 10 coin games a $50,000 volume bonus will be awarded in their ambassador team 1 and team 2.')}
          </li>

          <li>
            {t('Level III : $3,000 and they receive 3,000 coins to play. Their $3,000 volume counts to upline ambassador sponsors. Each day they play the 100, 25 and 10 coin games a $75,000 volume bonus will be awarded in their ambassador team 1 and team 2.')}
          </li>

          <li>
            {t('Level IV : $4,000 and they receive 4,000 coins to play. Their $4,000 volume counts to upline ambassador sponsors. Each day they play the 100, 25 and 10 coin games a $100,000 volume bonus will be awarded in their ambassador team 1 and team 2.')}
          </li>

          <li>
            {t('Level V : $6,000 and they receive 6,000 coins to play. Their $6,000 volume counts to upline ambassador sponsors. Each day they play the 100, 25 and 10 coin games a $150,000 volume bonus will be awarded in their ambassador team 1 and team 2.')}
          </li>
        </ul>
      </Grid>

    </Container>
  );
};

export default MemeGamerChallengePage;
