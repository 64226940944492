import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import Layout from '../../components/CardLayout';
import ComponentLoader from '../../components/ComponentLoader';
import { BoosterLeadPageContext, BoosterLeadPageProvider } from './context';
import Table from '../../components/Table';
import Button from '../../components/Button';
import PurchasePasses from '../Memberships/components/PurchasePasses';
import PurchasePackages from '../Memberships/components/PurchasePackages';
import Modal from '../../components/Modal';

const BoosterLeadPage = () => {
  const { navigate } = useNavigate();
  const {
    t,
    user,

    boosterLeadDataColumnsConfiguration,
    boosterLeadData,
    loadedBoosterLeadData,
  } = useContext(BoosterLeadPageContext);
  const [openModal, setOpenModal] = useState(false);

  const onPurchaseMore = () => {
    setOpenModal(true);
  };

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container className="booster-leads">
      <Layout
        maxWidth="100%"
        sx={{
          mt: 6,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            width: '100%',
          }}
        >
          {t('Booster Leads')}
        </Typography>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingTop: '14px' }}>
          <Button
            onClick={onPurchaseMore}
            className="py-auto my-auto mr-3"
            bordered
            size="sm"
          >
            <span className="no-wrap">{t('Purchase more coins')}</span>
          </Button>
        </Grid>

        <Modal
          open={openModal}
          width="600px"
          title="Purchase Coins"
          handleClose={() => {
            setOpenModal(false);
          }}
        >
          <Grid item xs={12} marginBottom={6}>
            <PurchasePasses />
          </Grid>

          <Grid item xs={12}>
            <PurchasePackages />
          </Grid>
        </Modal>
      </Layout>
      <Grid
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        {loadedBoosterLeadData ? (
          <Table
            loading={!loadedBoosterLeadData}
            columns={boosterLeadDataColumnsConfiguration}
            rows={boosterLeadData}
            className="table mb-4"
          />
        ) : (
          <div className="flex justify-center">
            <ComponentLoader />
          </div>
        )}
      </Grid>
    </Container>
  );
};

export default () => (
  <BoosterLeadPageProvider>
    <BoosterLeadPage />
  </BoosterLeadPageProvider>
);
