import {
  useCallback, useEffect, useMemo, useState, 
} from 'react';

const useScreenSize = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const isMobile = useMemo(() => screenWidth <= 850, [screenWidth]);

  const changeScreenSizeCallback = useCallback(() => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    changeScreenSizeCallback();
    window.addEventListener('resize', changeScreenSizeCallback);

    return () => window.removeEventListener('resize', changeScreenSizeCallback);
  }, []);

  return {
    screenWidth,
    screenHeight,
    isMobile,
  };
};

export default useScreenSize;
