import dayjs from 'dayjs';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function PackageCard({ data }) {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={8} md={6} xl={5} margin="0 auto" className="lookup-history-card">
      <Typography
        sx={{
          fontWeight: 600,
          color: 'var(--primary-color)',
          fontSize: '16px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {t('Result')}
      </Typography>
      <div className="divider" />
      <div className="flex justify-between">
        <Typography>
          {t('Type')}
          :
        </Typography>
        <Typography className="pl-2">{data.Type}</Typography>
      </div>

      <div className="flex justify-between">
        <Typography>
          {t('Generated By')}
          :
        </Typography>
        <Typography className="pl-2">{data.GeneratedBy}</Typography>
      </div>

      <div className="flex justify-between">
        <Typography>
          {t('Current Owner')}
          :
        </Typography>
        <Typography className="pl-2">{data.CurrentOwner}</Typography>
      </div>

      <div className="flex justify-between">
        <Typography>
          {t('Date Created')}
          :
        </Typography>
        <Typography className="pl-2">
          {dayjs(data.DateCreated).format('YYYY-MM-DD HH:MM')}
        </Typography>
      </div>

      <div className="flex justify-between">
        <Typography>
          {t('Token')}
          :
        </Typography>
        <Typography className="pl-2">{data.Token}</Typography>
      </div>

      <div className="flex justify-between">
        <Typography>
          {t('Used')}
          :
        </Typography>
        <Typography className="pl-2">
          {data.Used ? t('Used') : t('Not Used')}
        </Typography>
      </div>

      <div className="flex justify-between">
        <Typography>
          {t('Used By')}
          :
        </Typography>
        <Typography className="pl-2">{data.UsedBy || '-'}</Typography>
      </div>

      <div className="flex justify-between">
        <Typography>
          {t('Date Used')}
          :
        </Typography>
        <Typography className="pl-2">
          {data.DateUsed ? dayjs(data.DateUsed).format('YYYY-MM-DD HH:MM') : '-'}
        </Typography>
      </div>

      <div className="flex justify-between">
        <Typography>
          {t('Generate Method')}
          :
        </Typography>
        <Typography className="pl-2">{data.GenerateMethod}</Typography>
      </div>

      <div className="flex justify-between">
        <Typography>
          {t('Description')}
          :
        </Typography>
        <Typography className="pl-2">{data.Description}</Typography>
      </div>
    </Grid>
  );
}
