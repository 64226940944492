import { useState } from 'react';
import { getFormConfig } from './config';
import Form from '../../../../components/Form';
import usePurchasePass from './hook';
import Modal from './components/modal';
// import PurchasePassStripeModal from './components/stripeModal.jsx';

export default function PurchasePasses({ reset }) {
  const {
    submitPurchase, errors, setErrors,
    purchaseData, handleModalClose, defaultValue,
  } = usePurchasePass(reset);
  const [actions, setActions] = useState({ setFormValue: () => { } });
  const formConfig = getFormConfig(actions);

  return (
    <div>
      <h2>Purchase Packs</h2>

      <Form
        size="sm"
        config={formConfig}
        onSubmit={submitPurchase}
        submitLabel="Purchase"
        setActions={setActions}
        defaultFormValue={defaultValue}
        errors={errors}
        actions={actions}
        setErrors={setErrors}
        buttonType="submit"
        buttonStyle={{ minWidth: '182px' }}
      />

      {purchaseData && (
        <Modal
          data={purchaseData}
          handleModalClose={handleModalClose}
        />

      )}
      {/* <PurchasePassStripeModal
          errors={errors}
          submit=""
          data={purchaseData}
          handleModalClose={handleModalClose}
      /> */}
    </div>
  );
}
