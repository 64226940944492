/* eslint-disable react/no-danger */
import { useCallback, useEffect, useState } from 'react';
import useApi from '../../utils/api';
import Banner from '../Banner';

const GlobalBanners = () => {
  const { authGet } = useApi();
  const [globalBanners, setGlobalBanners] = useState([]);

  const fetchActiveBanners = useCallback(async () => {
    const response = await authGet('/global-banners/active');
    setGlobalBanners(response.data);
  }, [authGet]);

  useEffect(() => {
    fetchActiveBanners();
  }, []);

  return globalBanners?.length > 0 && (
    globalBanners.map((banner) => (
      <Banner
        key={banner.id}
        data={{
          message: (
            <span dangerouslySetInnerHTML={{ __html: banner.message }} />
          ),
        }}
      />
    ))
  );
};

export default GlobalBanners;
