import {
  useContext, useEffect, useRef, useState, 
} from 'react';
import { Typography } from '@mui/material';
import { BoosterLeadAdminContext } from '../context';
import Modal from '../../../../components/Modal';
import TextInput from '../../../../components/Inputs/TextInput';
import Button from '../../../../components/Button';

const AddAmbassadorModal = () => {
  const {
    t,
    openAddAmbassadorModal,
    handleAddAmbassadorModalSubmit,
    handleCloseAmbassadorModal,

    fetchAmbassadors,
    clearAmbassadorsData,
    handleChangeSelectedAmbassador,
    selectedAmbassador,
    ambassadorsData,
    loadedAmbassadorsData,
  } = useContext(BoosterLeadAdminContext);

  const [searchKey, setSearchKey] = useState('');
  const [inputFocused, setInputFocused] = useState(false);

  const timeoutId = useRef(null);

  const handleSelectAmbassador = (data) => {
    setSearchKey('');
    handleChangeSelectedAmbassador(data);
    setInputFocused(false);
  };

  const handleInputFocused = () => {
    if (!inputFocused) {
      fetchAmbassadors({});
      setInputFocused(true);
    }
  };

  useEffect(() => {
    clearTimeout(timeoutId.current);

    if (searchKey.length > 1) {
      timeoutId.current = setTimeout(() => {
        fetchAmbassadors({ search: searchKey });
      }, 900);
    } else {
      clearAmbassadorsData();
    }
  }, [searchKey]);

  return (
    <Modal
      title={t('Round Robin')}
      open={openAddAmbassadorModal}
      handleClose={() => {
        handleCloseAmbassadorModal();
        setSearchKey('');
        setInputFocused(false);
      }}
      width="600px"
    >
      <div className="flex flex-column mb-2">
        <Typography>Search Ambassador</Typography>
        <TextInput
          placeholder="ej"
          size="sm"
          value={searchKey}
          onChange={(val) => setSearchKey(val)}
          onFocus={() => handleInputFocused()}
        />
      </div>

      {loadedAmbassadorsData && (
        <div className="admin-ambassadors-container">
          <div className="options">
            {ambassadorsData.map((data, idx) => (
              <button
                type="button"
                onClick={() => handleSelectAmbassador(data)}
                key={idx}
              >
                {`${data.Username} - ${data.Package}`}
              </button>
            ))}
          </div>
        </div>
      )}

      <Button
        size="md"
        style={{
          width: '100%',
          padding: '14px',
          margin: '24px 0',
          textTransform: 'uppercase',
        }}
        onClick={() => handleAddAmbassadorModalSubmit()}
        disabled={!selectedAmbassador}
      >
        {selectedAmbassador
          ? t(`Add ${selectedAmbassador.Username} (${selectedAmbassador.SID})`)
          : t('Add')}
      </Button>
    </Modal>
  );
};

export default AddAmbassadorModal;
