import { Tooltip } from '@mui/material';
import { renderNumber } from '../../utils/string';

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

export const leaderboardYesterdayColumnsConfig = (t) => [
  {
    field: 'User',
    headerName: t('NAME'),
    flex: 1,
    minWidth: 300,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <div className="flex w-full" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="flex">
          <span className="emoji" style={{ lineHeight: 'normal', marginRight: '5px', marginTop: '4px' }}>{`${row.User.Country?.emoji || ''}`}</span>
          <span>{row.User.Username}</span>
        </div>
        <div className="flex" style={{ gap: 8, paddingRight: 8 }}>
          {row.memeCoins.filter((coin) => coin.tokens > 0).map((coin) => (
            <Tooltip title={coin.name} arrow placement="top-end">
              <picture className="leaderboard-memecoin-logo">
                <img src={coin.image} height="32px" alt={coin.name} />
              </picture>
            </Tooltip>
          ))}
        </div>
      </div>
    ),
  },
  {
    field: 'votesCount',
    headerName: t('GAMES PLAYED'),
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value) : '0'),
  },
  {
    field: 'token',
    headerName: t('MG TOKENS'),
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value) : '0'),
  },
];

export const leaderboardColumnsConfig = (t) => [
  {
    field: 'User',
    headerName: t('NAME'),
    flex: 1,
    minWidth: 300,
    ...defaultColConfig,
    renderCell: ({ value }) => (
      <>
        <span className="emoji" style={{ lineHeight: 'normal', marginRight: '5px' }}>{`${value.Country?.emoji || ''}`}</span>
        <span>{`${value.Username}`}</span>
      </>
    ),
  },
  {
    field: 'tokenTotalHistorical',
    headerName: t('TOTAL MG TOKENS'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value) : '0'),
  },
];

export const leaderboardFullColumnsConfig = (t) => [
  {
    field: 'User',
    headerName: t('NAME'),
    flex: 1,
    minWidth: 300,
    ...defaultColConfig,
    renderCell: ({ value }) => (
      <>
        <span className="emoji" style={{ lineHeight: 'normal', marginRight: '5px' }}>{`${value.Country?.emoji || ''}`}</span>
        <span>{value.Username}</span>
      </>
    ),
  },
  {
    field: 'VotesCount',
    headerName: t('PLAYED GAMES'),
    minWidth: 150,
    ...defaultColConfig,
  },
  {
    field: 'tokenTotalHistorical',
    headerName: t('TOTAL MG TOKENS'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => (value ? renderNumber(value) : '0'),
  },
];
