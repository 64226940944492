import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { PulseLoader } from 'react-spinners';
import dayjs from 'dayjs';
import Modal from '../../../../../components/Modal';
import { Context } from '../../context';

export default function UserModal() {
  const { t } = useTranslation();
  const {
    selectedUser,
    handleUserModalClose,
    loadingselectedUser,
  } = useContext(Context);

  const dateExpiring = selectedUser?.dateExpiring ? dayjs(selectedUser?.dateExpiring).format('MMM DD, YYYY') : 'N/A';

  return (
    <Modal
      title={(
        <Typography sx={{ textTransform: 'uppercase', fontWeight: 600 }}>
          {' '}

          {selectedUser?.displayName || 'Loading ...'}

        </Typography>
      )}
      width="550px"
      open={loadingselectedUser || !!selectedUser}
      handleClose={handleUserModalClose}
    >
      {
        (selectedUser && !loadingselectedUser) ? (
          <Container className="user-modal">
            <Typography className="section-title">{t('MEMBER DETAILS')}</Typography>
            <div className="divider" />

            <div className="user-data">
              <Typography className="user-data-title">{t('Username')}</Typography>
              <Typography className="user-data-value">
                {selectedUser?.username}
              </Typography>
            </div>

            <div className="user-data">
              <Typography className="user-data-title">{t('Team Volume')}</Typography>
              <Typography className="user-data-value">
                {`$${selectedUser?.revealedPv || 0}`}
              </Typography>
            </div>
            {/* <div className="user-data">
            <Typography className="user-data-title">{t('Join Date')}</Typography>
            <Typography className="user-data-value">
              {moment(selectedUser?.DateJoined).format('MM-DD-YYYY')}
  
            </Typography>
          </div> */}

            <div className="user-data">
              <Typography className="user-data-title">{t('Status')}</Typography>
              <Typography sx={{ textTransform: 'uppercase', color: selectedUser?.active ? '#71f771' : '#ff8080' }} className="user-data-value">
                {`${selectedUser?.active ? 'Active' : 'Inactive'}`}
              </Typography>
            </div>
            <div className="user-data">
              <Typography className="user-data-title">{t('Qualified')}</Typography>
              <Typography className="user-data-value">
                {`${selectedUser?.qualified ? 'Yes' : 'No'}`}
              </Typography>
            </div>

            <div className="user-data">
              <Typography className="user-data-title">{t('Rank')}</Typography>
              <Typography className="user-data-value">
                {`${selectedUser?.rank.name || 'Ambassador'}`}
              </Typography>
            </div>

            <div className="user-data">
              <Typography className="user-data-title">{t('Expiration Date')}</Typography>
              <Typography className="user-data-value">
                {`${selectedUser?.isProduct ? dateExpiring : 'N/A'}`}
              </Typography>
            </div>

            <Typography
              onClick={handleUserModalClose}
              sx={{
                textDecoration: 'underline',
                display: 'flex',
                justifyContent: 'center',
                mt: 5,
                cursor: 'pointer',
              }}
            >
              {' '}
              {t('Close')}
            </Typography>
          </Container>
        ) : (
          <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography>
              {' '}
              <PulseLoader />
            </Typography>
          </Container>
        )
      }

    </Modal>
  );
}
