export default function AIData({ coin = {} }) {
  // console.log(coin);
  return (
    <div className="ai-data">
      {coin.description ? (
        <p>
          {coin.description}
        </p>
      ) : null}

      {Array.isArray(coin.categories) ? (
        <div className="categories">
          {coin.categories.map((category) => (
            <div className="category" key={category}>
              <span>
                {category}
              </span>
            </div>
          ))}
        </div>
      ) : null}

    </div>
  );
}
