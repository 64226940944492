import {
  Card,
  CardMedia, Container, Grid, Typography,
} from '@mui/material';

const IntroVideo = () => (
  <Container sx={{ mt: '40px' }} maxWidth="xl">
    <Grid
      xl={12}
      lg={12}
      md={12}
      xs={12}
      sm={12}
      item
      sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}
    >
      <div className="intro-video w-full">
        <Typography fontSize="32px" textAlign="center">
          Intro Video
        </Typography>
        <Card sx={{
          maxWidth: '1150px',
          margin: 'auto',
          mt: 5,
          height: {
            xs: '280px', sm: '550px', md: '600px', lg: '850px',
          },
        }}
        >
          <CardMedia
            component="iframe"
            height="100%"
            src="https://s3.us-east-1.amazonaws.com/cdn.memegames.ai/assets/intro-video-2.mp4"
            title="Intro Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ border: 'none' }}
          />
        </Card>
      </div>
    </Grid>
  </Container>
);

export default IntroVideo;
