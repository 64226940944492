import {
  useCallback, useContext, useEffect, useMemo, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import { bannerFormConfig, bannerFormValidationConfig } from '../config';
import { validateAll } from '../../../../components/Form/config';

const usePendingRedeemApi = () => {
  const {
    authGet, authPost, authPut, authDelete, 
  } = useApi();

  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const [bannerData, setBannerData] = useState(null);
  const [bannerToDelete, setBannerToDelete] = useState(null);
  const [formErrors, setFormErrors] = useState();
  const [formEditing, setFormEditing] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [allBanners, setAllBanners] = useState([]);
  const [loadedAllBanners, setLoadedAllBanners] = useState(false);

  const fetchAllBannersData = useCallback(
    async () => {
      setLoadedAllBanners(false);
      const response = await authGet('/global-banners');
      
      setAllBanners(response.data);
      
      setLoadedAllBanners(true);
    },
    [authGet, setAllBanners, setLoadedAllBanners],
  );

  const handleFormSubmit = useCallback(
    async (data) => {
      setFormSubmitting(true);
      
      const checkData = validateAll(data, bannerFormValidationConfig);

      if (checkData) {
        setFormErrors(checkData);
        setFormSubmitting(false);
        return;
      }

      try {
        if (!formEditing) {
          await authPost('/global-banners', { data });

          toast.success('Banner added successfully');
        } else {
          await authPut(`/global-banners/${data.id}`, { data });

          toast.success('Banner edited successfully');
        }
      } catch (err) {
        console.log(err);
        toast.error('Error creating the Banner');
      } finally {
        setFormErrors(null);
        setBannerData(null);
        setFormEditing(false);
        fetchAllBannersData();
      }
      setFormSubmitting(false);
    },
    [authPost, formEditing],
  );

  const handleDeleteSubmit = useCallback(
    async ({ bannerId }) => {
      setFormSubmitting(true);
      try {
        await authDelete(`/global-banners/${bannerId}`);
        toast.success('The banner has been deleted.');
      } catch (err) {
        console.log(err);
        toast.error('Error deleting banner');
      } finally {
        setBannerToDelete(null);
        fetchAllBannersData();
      }
      setFormSubmitting(false);
    },
    [authDelete],
  );

  const handleOpenModal = useCallback(() => {
    setFormSubmitting(false);
    setBannerData({
      title: '',
      message: '',
      publicUntil: dayjs().add(30, 'day'),
    });
  }, [setBannerData]);

  const handleOpenModalToEdit = useCallback(
    (data) => {
      setFormEditing(true);
      setBannerData(data);
    },
    [setBannerData, setFormEditing],
  );

  const handleOpenModalToDelete = useCallback(
    (data) => {
      setBannerToDelete(data);
    },
    [setBannerToDelete],
  );

  const handleCloseModal = useCallback(
    () => {
      setFormErrors(null);
      setBannerData(null);
      setFormSubmitting(false);
    },
    [setBannerData],
  );

  const handleCloseDeleteModal = useCallback(
    () => {
      setBannerToDelete(null);
    },
    [setBannerToDelete],
  );

  const bannerFormConfiguration = useMemo(() => bannerFormConfig, [bannerFormConfig]);

  useEffect(() => {
    fetchAllBannersData();
  }, []);

  return {
    t,
    user,

    allBanners,
    loadedAllBanners,
    bannerData,
    bannerToDelete,
    formSubmitting,
    formErrors,
    setFormErrors,
    handleFormSubmit,
    handleDeleteSubmit,
    handleOpenModal,
    handleCloseModal,
    handleCloseDeleteModal,
    handleOpenModalToEdit,
    handleOpenModalToDelete,

    bannerFormConfiguration,
  };
};

export default usePendingRedeemApi;
