/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  background: 'rgba(217, 217, 217, 0.03)',
  borderRadius: '8px 8px 8px 8px',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon color="primary" sx={{ fontSize: '17px' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(217, 217, 217, 0.03)',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
    borderRadius: '8px 8px 0px 0px',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    borderRadius: '8px 8px 8px 8px',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '8px 8px 8px 8px',
}));

const CustomAccordion = ({
  summary,
  details,
  expanded,
  setExpanded,
  sx = {},
  className = '',
  _id = '',
  toggleText = '',
}) => (
  <Accordion expanded={expanded} onChange={setExpanded} className={className} sx={{ ...sx, mb: 2 }}>
    <AccordionSummary
      aria-controls={`panel-content-${_id}`}
      id={`panel-header-${_id}`}
    >
      <div className="flex justify-between w-full summary-container">
        <div>{summary}</div>
        <div className="toggleText my-auto py-auto ">{toggleText}</div>
      </div>
    </AccordionSummary>
    <AccordionDetails>{details}</AccordionDetails>
  </Accordion>
);

export { Accordion, AccordionDetails, AccordionSummary };

export default CustomAccordion;
