import { useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Grid } from '@mui/material';
import AIData from '../../features/Home/components/memeGame/VoteCoin/AIData';
import TradingViewWidget from '../../features/Home/components/memeGame/VoteCoin/TradingViewWidget';
import Modal from '../Modal';
import getGeckoData from '../../utils/api/getGeckoData';
import ComponentLoader from '../ComponentLoader';
import Icon from '../Icon';
import { renderNumber } from '../../utils/string';

const MoreInfoModal = ({ coin, coinVoteData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [coinData, setCoinData] = useState(null);

  const fetchCoinData = async () => {
    const data = await getGeckoData(coin?.apiId);
    setCoinData({
      ...coin,
      voteData: coinVoteData,
      data,
      solanaId: data?.platforms?.solana,
      description: data?.description?.en,
      categories: data?.categories,
      communityData: data?.community_data,
    });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    fetchCoinData();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCoinData(null);
  };
  
  return (
    <>
      <button
        onClick={handleOpenModal}
        type="button"
        aria-label="More Info"
        className="more-info-button"
      >
        <Icon name="Chart" width={16} height={12} />
        <span style={{ whiteSpace: 'nowrap' }}>More Info</span>
      </button>
      <Modal
        title={`${coin?.name} Info`}
        open={openModal}
        titleStyle={{ px: '80px', textALign: 'center' }}
        handleClose={handleCloseModal}
        width="1000px"
      >
        {coinData ? (
          <>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="space-between"
                sx={{ paddingRight: { md: '48px' } }}
              >
                <span>Start Price:</span>
                <span>
                  <b>
                    {`$${coinData?.voteData.start.price}`}
                  </b>
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="space-between"
                sx={{ paddingRight: { md: '48px' } }}
              >
                <span>End Price:</span>
                <span>
                  <b>
                    {`$${coinData?.voteData.end.price}`}
                  </b>
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="space-between"
                sx={{ paddingRight: { md: '48px' } }}
              >
                <span>Price Change:</span>
                <span>
                  <div className="card-chip">
                    <div className="flex flex-column mr-2">
                      <span style={{ fontSize: '10px' }}>
                        {coinData?.voteData.priceChange}
                        %
                      </span>
                    </div>
                    <div className="flex flex-column">
                      {coinData?.voteData.priceChange > 0 ? (
                        <div className="chip-bubble">
                          <div className="flex justify-center">
                            <ArrowUpwardIcon style={{ width: 10, height: 10, color: 'black' }} />
                          </div>
                        </div>
                      ) : (
                        <div className="chip-bubble not-growing-bubble">
                          <div className="flex justify-center">
                            <ArrowDownwardIcon style={{ width: 10, height: 10, color: 'black' }} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="space-between"
                sx={{ paddingRight: { md: '48px' } }}
              >
                <span>Market Cap:</span>
                <span>
                  <b>
                    {`$${renderNumber(coinData?.data?.market_data?.market_cap?.usd || 0)}`}
                  </b>
                </span>
              </Grid>
            </Grid>
            <div style={{ height: 500, marginBottom: 12 }}>
              <TradingViewWidget solanaId={coinData.solanaId} />
            </div>
            <AIData coin={coinData} />
          </>
        ) : (
          <ComponentLoader />
        )}
      </Modal>
    </>
  );
};

export default MoreInfoModal;
