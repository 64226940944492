import { useContext } from 'react';
import Modal from '../../../../components/Modal';
import { AdminPodcastContext } from '../context';
import Form from '../../../../components/Form';
import { podcastFormConfig } from '../config';

const AddEditPodcastModal = () => {
  const {
    t,
    podcast,
    handleCloseModal,
    handleSubmitFormModal,
    podcastFormLoading,

    podcastFormErrors,
    setPodcastFormErrors,
  } = useContext(AdminPodcastContext);

  const isEdit = podcast?.id?.length > 0;

  return (
    <Modal
      title={!isEdit ? t('Add New Podcast') : t('Edit Podcast')}
      open={!!podcast}
      handleClose={handleCloseModal}
      width="650px"
    >
      <Form
        config={podcastFormConfig}
        errors={podcastFormErrors}
        setErrors={setPodcastFormErrors}
        onSubmit={handleSubmitFormModal}
        defaultFormValue={podcast}
        formLoading={podcastFormLoading}
        buttonType="button"
        buttonStyle={{ width: '100%' }}
      />
    </Modal>
  );
};

export default AddEditPodcastModal;
