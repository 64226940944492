/* eslint-disable react/jsx-filename-extension */
/* eslint-disable object-curly-newline */
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { colConfig, defaultFormData } from './config';
import useApi from '../../../../../utils/api';
import useAuthenticaitonStore from '../../../../../utils/store/authentication/hook';
import Icon from '../../../../../components/Icon';

const PAGE_SIZE = 25;

const useMembersListApi = () => {
  const { authenticateUser } = useAuthenticaitonStore();
  const [formData, setFormData] = useState();
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authGet, authPost } = useApi();
  const [rows, setRows] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [loading, setLoading] = useState(false);
  const [addBonusModalData, setAddBonusModalData] = useState();
  const [removeBonusModalData, setRemoveBonusModalData] = useState();
  const [sponsorFilter, setSponsorFilter] = useState();
  const [ranksFilter, setRanksFilter] = useState();
  const [sortModel, setSortModel] = useState([
    {
      field: 'DateJoined',
      sort: 'desc',
    },
  ]);
  
  // #region Users Table

  const getUsers = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const sortField = `${sortModel ? sortModel[0]?.field : 'DateJoined'}`;
        const dir = sortModel[0]?.sort === 'asc' ? '1' : '-1';
        const res = await authGet('/users', {
          params: {
            page,
            perPage: PAGE_SIZE,
            orderBy: { [sortField]: dir },
            search: searchText,
            ...(sponsorFilter ? { sponsor: sponsorFilter.id } : {}),
            ...(ranksFilter !== 'all' ? { rank: ranksFilter } : {}) },
        });
        setRows(
          res?.data?.map((item) => ({
            id: item.SID,
            ...item,
          })),
        );
        setPaginationData({
          totalUsers: res.total,
          total: Math.ceil(res.total / PAGE_SIZE),
          page,
        });
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [authGet, paginationData, searchText, setLoading, sponsorFilter, ranksFilter, sortModel],
  );

  useEffect(() => {
    getUsers();
  }, [sponsorFilter, ranksFilter, sortModel]);

  const onDelete = () => {};
  
  const onEdit = (params) => {
    // setFormData(params.row);
    navigate(`/admin/members/${params.row.id}`);
  };
  
  const onView = (id) => {
    navigate(`/admin/members/${id}`);
  };
  
  const onAdd = useCallback(() => {
    setFormData(defaultFormData);
  }, [formData]);

  const onSimulate = useCallback(async (sid) => {
    setLoading(true);
    try {
      const res = await authGet(`/auth/impersonate?sid=${sid}`);
      authenticateUser(res.user, res.token);
      sessionStorage.setItem('_imp', 1);
      // navigate('/');
      window.location.href = '/';
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, []);
  
  const initAddBonus = useCallback(async (user) => {
    setAddBonusModalData(user);
  }, []);
  
  const initRemoveBonus = useCallback(async (user) => {
    setRemoveBonusModalData(user); 
  }, []);
  
  const handleAddBonusModalClose = () => {
    setAddBonusModalData(null);
  };
  
  const handleRemoveBonusModalClose = () => {
    setRemoveBonusModalData(null);
  };
  
  const onAddBonus = useCallback(async (user, bonus) => {
    setLoading(true);
    try {
      if (!bonus || bonus < 0) {
        toast.error('Invalid bonus amount');
        setLoading(false);
        return;
      }
      if (Array.isArray(user)) {
        await authPost('/users/add-bonuses', {
          data: { amount: bonus, sids: user },
        });
      } else {
        const sid = user.SID;
        await authPost(`/users/add-bonus/${sid}`, {
          data: { amount: bonus },
        });
      }
      getUsers(paginationData?.page || 1);
    } catch (err) {
      console.log('onAddBonus--', err);
      toast.error('Failed to add bonus');
    }
    setAddBonusModalData(null);
    setLoading(false);
  }, []);
  
  const onRemoveBonus = useCallback(async (user) => {
    setLoading(true);
    try {
      if (Array.isArray(user)) {
        await authPost('/users/add-bonuses', {
          data: { amount: 0, sids: user },
        });
      } else {
        const sid = user.SID;
        await authPost(`/users/add-bonus/${sid}`, {
          data: { amount: 0 },
        });
      }
      getUsers(paginationData?.page || 1);
    } catch (err) {
      console.log('onRemoveBonus--', err);
      toast.error('Failed to remove bonus');
    }
    setRemoveBonusModalData(null);
    setLoading(false);
  }, []);
  
  const onModalClose = () => {
    setFormData(null);
  };

  const columns = [
    {
      field: 'Name',
      headerName: t('NAME'),
      width: 250,
      ...colConfig({ onView }),
    },
    {
      field: 'EnrolledParentID',
      headerName: t('Sponsor'),
      width: 250,
      ...colConfig({ onView }),
    },
    {
      field: 'rankName',
      headerName: t('Rank'),
      width: 150,
      ...colConfig,
    },
    {
      field: 'active',
      headerName: t('Status'),
      width: 80,
      ...colConfig,
      renderCell: ({ value }) => (
        <Grid item xs={12}>
          {value ? (
            <div>
              <Icon height={20} width={20} name="Checkmark" />
            </div>
          ) : (
            <div>
              <Icon height={20} width={20} name="UserX" />
            </div>
          )}
        </Grid>
      ),
    },
    {
      field: 'Email',
      headerName: t('Email'),
      width: 290,
      ...colConfig,
    },
    {
      field: 'DateJoined',
      headerName: t('Joined'),
      width: 130,
      renderCell: ({ value }) => dayjs(value).format('MMM DD, YYYY'),
    },
    {
      field: 'DateExpiring',
      headerName: t('Expire'),
      width: 130,
      renderCell: ({ row, value }) => (row.isProduct ? dayjs(value).format('MMM DD, YYYY') : '-'),
    },
    {
      field: 'actions',
      headerName: '',
      width: 180,
      ...colConfig({ 
        onDelete, 
        onEdit, 
        onSimulate, 
        onAddBonus, 
        onRemoveBonus, 
        loading, 
        initAddBonus, 
        initRemoveBonus,
      }),
    },
  ];

  // #endregion
  
  // #region Search

  const searchClick = useCallback(() => {
    getUsers(paginationData.page);
  }, [searchText, paginationData]);

  return {
    rows,
    columns,
    searchText,
    setSearchText,
    searchClick,
    formData,
    onAdd,
    onModalClose,
    paginationData,
    getUsers,
    onSimulate,
    loading,
    initAddBonus,
    initRemoveBonus,
    handleRemoveBonusModalClose,
    handleAddBonusModalClose,
    addBonusModalData,
    removeBonusModalData,
    onAddBonus,
    onRemoveBonus,
    setAddBonusModalData,
    setRemoveBonusModalData,
    sponsorFilter,
    setSponsorFilter,
    ranksFilter,
    setRanksFilter,
    
    setSortModel,
    sortModel,
  };
};

export default useMembersListApi;
