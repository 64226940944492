export const podcastFormConfig = [
  {
    name: 'title',
    type: 'text',
    label: 'Title',
    placeholder: 'Podcast title',
    required: true,
  },
  {
    name: 'description',
    type: 'text',
    label: 'Description',
    placeholder: 'Podcast description',
    required: true,
  },
  {
    name: 'releaseDate',
    type: 'date',
    label: 'Release Date',
  },
  {
    name: 'file',
    type: 'file',
    label: 'Audio File',
    accept: 'audio/*',
    required: true,
  },
  {
    name: 'published',
    type: 'muiCheckbox',
    label: 'Published',
    checked: true,
  },
];

export const podcastFormValidationConfig = {
  title: { isEmpty: false, type: 'String', message: 'Title is required' },
  description: { isEmpty: false, type: 'String', message: 'Description is required' },
  file: { isEmpty: false, type: 'String', message: 'Audio File is required' },
};

export const podcastsTableConfig = {};
