/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Grid, Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import dayjs from 'dayjs';
import { Context } from '../../../context';
import VoteCoin from '../VoteCoin';
import Layout from '../../../../../components/CardLayout';
import Button from '../../../../../components/Button';

const YesterdayGameMatchUp = ({
  setAdminModalGame,
  setOpenAdminModal,
  renderOtherConts,
  isMobile,
  gameMatch,
}) => {
  const matchVotes = Object.entries(gameMatch?.game?.votes ?? []).map(([, vote]) => vote);
  const matchVotesCount = matchVotes.length;

  const [currentVoteIndex, setCurrentVoteIndex] = useState(0);
  const [currentVote, setCurrentVote] = useState(
    matchVotesCount > 0 ? matchVotes[0] : null,
  );

  const { tokens, user } = useContext(Context);

  const handleNextMatchClick = () => {
    if (currentVoteIndex < matchVotesCount - 1) {
      setCurrentVoteIndex(currentVoteIndex + 1);
    }
  };

  const handlePrevMatchClick = () => {
    if (matchVotesCount > 0 && currentVoteIndex > 0) {
      setCurrentVoteIndex(currentVoteIndex - 1);
    }
  };

  useEffect(() => {
    const selectedVote = matchVotesCount > 0 ? matchVotes[currentVoteIndex][1] : null;
    setCurrentVote(selectedVote);
  }, [currentVoteIndex]);

  return (
    <div className="matchup-container history">
      <div className="matchup-header">
        {currentVote ? (
          <div className="time">
            <b>{`Game ${currentVoteIndex + 1} :`}</b>
            {currentVote.locked ? (
              <>
                <span>
                  {`${dayjs(currentVote.lastVotedAt).tz('UTC').format('HH:mm')}`}
                </span>
                <span style={{
                  fontSize: '8px', position: 'relative', top: '0px', marginLeft: '6px', 
                }}
                >
                  <LockIcon style={{ fontSize: '18px' }} />
                </span>
              </>
            ) : (
              <span>
                {`${dayjs(currentVote.createdAt).tz('UTC').format('HH:mm')}`}
              </span>
            )}
          </div>
        ) : (
          <div className="time" />
        )}
        <div className="controls">
          {matchVotesCount > 0 ? `${currentVoteIndex + 1} of ${matchVotesCount}` : '0 Games'}
          <Button
            type="button"
            size="sm"
            bordered
            disabled={currentVoteIndex === 0}
            onClick={handlePrevMatchClick}
          >
            <ChevronLeft fontSize="medium" />
          </Button>
          <Button
            type="button"
            size="sm"
            bordered
            disabled={currentVoteIndex >= matchVotesCount - 1}
            onClick={handleNextMatchClick}
          >
            <ChevronRight fontSize="medium" />
          </Button>
        </div>
      </div>
      <div className="matchup-body">
        <div className="flex-1 matchup-slide current">
          <Layout
            maxWidth="100%"
            cardMainClassName="flex matchup-card"
            size="xsm"
            cardStyle={{
              paddingLeft: 0,
              paddingRight: 0,
              overflow: 'visible',
              marginTop: 0,
              borderRadius: 20,
            }}
          >
            <div className="flex flex-column gap-1 justify-center flex-1 meme-coins game-container">
              <div className="flex flex-column">
                <div className="flex flex-wrap mb-4 gap-2 vote-coin-header">
                  <div
                    className="price-badge"
                    onClick={() => {
                      if (['glek', 'bighead', 'ej', 'ammy'].includes(user?.Username)) {
                        setAdminModalGame(gameMatch?.game);
                        setOpenAdminModal(true);
                      }
                    }}
                  >
                    <span>
                      {gameMatch?.game?.scheduleGameId?.price?.toLocaleString('en-us')
                        || 0}
                      {' '}
                    </span>
                    <span className="primary">Coins</span>
                  </div>
                  <div
                    className="price-badge-mobile"
                    onClick={() => {
                      if (['glek', 'bighead', 'ej', 'ammy'].includes(user?.Username)) {
                        setAdminModalGame(gameMatch?.game);
                        setOpenAdminModal(true);
                      }
                    }}
                  >
                    <div className="potential-points ">
                      <div className="right">
                        <span>
                          {gameMatch?.game?.scheduleGameId?.price.toLocaleString(
                            'en-us',
                          ) || 0}
                        </span>
                      </div>
                      <div className="left">
                        {/* // changed to points */}
                        <span>Coins</span>
                      </div>
                    </div>
                  </div>
                </div>
                <Box className="game-container-box">
                  <Grid
                    className="vote-coin-container"
                    container
                    columnSpacing={{
                      xl: 1,
                      lg: 1,
                      md: 1,
                      sm: 1,
                      xs: '10px',
                    }}
                  >
                    {(gameMatch?.coins || []).map((coin, index) => (
                      <VoteCoin
                        todayGame={gameMatch.game}
                        index={index}
                        username={user.Username}
                        coin={coin}
                        coinId={coin.id}
                        coinData={coin}
                        isYesterday
                        key={index}
                        isMobile={isMobile}
                        userTokens={tokens}
                      />
                    ))}
                  </Grid>
                </Box>
              </div>

              <img className="vs-image" alt="vs" src="/images/vs-badge.png" />
            </div>
            {renderOtherConts(gameMatch.game, currentVoteIndex, setCurrentVoteIndex)}
          </Layout>
        </div>
      </div>
      <div className="matchup-footer">
        {matchVotesCount > 1 && [...matchVotes.keys()].map((gameIndex) => (
          <Button
            key={gameIndex}
            type="button"
            size="sm"
            bordered={!(gameIndex === currentVoteIndex)}
            onClick={() => setCurrentVoteIndex(gameIndex)}
          />
        ))}
      </div>
    </div>
  );
};

export default YesterdayGameMatchUp;
