import { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../utils/store';
import Modal from '../Modal';
import Button from '../Button';

const AddIGMessageModal = () => {
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const [hideModal, setHideModal] = useState(true);
  const navigate = useNavigate();

  const handleCloseModal = useCallback(
    () => {
      setHideModal(false);
    },
    [user],
  );

  const handleYesButton = () => {
    setHideModal(false);
    navigate('/profile');
  };

  const handleNoButton = () => {
    localStorage.setItem('accepted_ig_message', 'true');
    setHideModal(false);
  };

  useEffect(() => {
    const acceptedModal = localStorage.getItem('accepted_ig_message');

    const userAddedIG = 'instagram' in user;

    setHideModal(!acceptedModal && !userAddedIG);
  }, []);

  return (
    <Modal
      open={!!user && hideModal}
      title="Add Instagram Account"
      handleClose={() => handleCloseModal()}
      width="550px"
    >
      <Typography
        fontSize="16px"
        fontWeight={500}
        textAlign="center"
        marginBottom="40px"
      >
        {t('Do you want to add your Instagram Account to your profile?')}
      </Typography>
      <Grid
        item
        xs={12}
        display="flex"
        sx={{ 
          justifyContent: { xs: 'center', md: 'flex-end' },
          flexDirection: { xs: 'column-reverse', md: 'row' },
          alignItems: 'center',
          gap: { xs: '16px', md: '20px' },
        }}
      >
        <Button
          onClick={handleNoButton}
          size="sm"
          type="button"
          bordered
          style={{ width: '100%', padding: '12px', fontSize: '14px' }}
        >
          {t('Don\'t show again')}
        </Button>
        <Button
          onClick={handleYesButton}
          size="sm"
          type="button"
          style={{ width: '100%', padding: '12px', fontSize: '14px' }}
        >
          {t('Yes, go to Profile')}
        </Button>
      </Grid>
    </Modal>
  );
};

export default AddIGMessageModal;
