const packageFormConfig = [
  {
    name: 'name',
    isText: false,
    type: 'text',
    label: 'Pack Type Name',
  },
  {
    name: 'description',
    isText: false,
    type: 'textarea',
    label: 'Description',
  },
  {
    name: 'price',
    isText: false,
    type: 'money',
    label: 'Price',
  },
  {
    name: 'points',
    isText: false,
    type: 'number',
    label: 'Coins',
  },
  {
    name: 'days',
    isText: false,
    type: 'number',
    label: 'Days',
  },
  // {
  //   name: 'commissionAmount',
  //   isText: false,
  //   type: 'money',
  //   label: 'Commission Amount',
  // },
  {
    name: 'maxPurchaseUnits',
    isText: false,
    type: 'number',
    label: 'Maximum purchase units',
  },
  {
    name: 'purchaseWith',
    type: 'select',
    options: [
      { value: 'all', label: 'All Payment Processors' }, 
      { value: 'btc', label: 'BTC' }, 
      { value: 'solana', label: 'Solana' },
    ],
    label: 'Purchase Only With',
  },
  {
    name: 'active',
    label: 'Active',
    type: 'boolean',
    checked: true,
    labelClassName: 'pt-3',
  },
  {
    name: 'useOnSignUp',
    label: 'Use On Sign Up',
    type: 'boolean',
    checked: true,
    // formGroupType: ' row-reverse',
    labelClassName: 'pt-3',
  },
  {
    name: 'calculateOnPv',
    label: 'Caclulate as PV',
    type: 'boolean',
    checked: true,
    // formGroupType: ' row-reverse',
    labelClassName: 'pt-3',
  },
  {
    name: 'transferable',
    label: 'Transferable',
    type: 'boolean',
    checked: true,
    // formGroupType: ' row-reverse',
    labelClassName: 'pt-3',
  },
  {
    name: 'activateImmediately',
    label: 'Activate Immediately After Purchase',
    type: 'boolean',
    checked: true,
    // formGroupType: ' row-reverse',
    labelClassName: 'pt-3',
  },
  {
    name: 'addTeamBonus',
    label: 'Add Team Bonus After Purchase',
    type: 'boolean',
    checked: true,
    labelClassName: 'pt-3',
  },
  {
    name: 'teamBonusAmount',
    label: 'Team Bonus Amount',
    type: 'money',
    labelClassName: 'pt-3',
    showOnCondition: true,
    showOnConditionField: 'addTeamBonus',
  },
];

export const defaultFormValues = {
  name: '',
  description: '',
  price: 0,
  points: '0',
  days: '30',
  commissionAmount: '0',
  maxPurchaseUnits: '100',
  active: true,
  useOnSignUp: true,
  calculateOnPv: true,
  transferable: true,
  activateImmediately: false,
  addTeamBonus: false,
  teamBonusAmount: '0',
  purchaseWith: 'all',
};

export const formValidationConfig = {
  name: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  // description: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  days: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
};

export const passTypesColors = [
  'green',
  'platinium',
  'gold',
  'sky',
  'rose',
  'purple',
  'blue',
  'orange',
  'red',
];

export default packageFormConfig;
