import { createContext } from 'react';
import useAdminPodcast from './hook';

export const AdminPodcastContext = createContext({
  t: () => {},
  user: {},

  podcast: {},
  podcastsData: {},
  podcastsDataLoaded: false,
  podcastFormLoading: false,

  getPodcastsData: () => {},
  handleOpenModalToAdd: () => {},
  handleOpenModalToEdit: () => {},
  handleCloseModal: () => {},
  handleSubmitFormModal: () => {},

  podcastDelete: {},

  handleOpenDeleteModal: () => {},
  handleCloseDeleteModal: () => {},
  handleSubmitDeleteModal: () => {},

  podcastFormErrors: {},
  setPodcastFormErrors: () => {},

  paginationData: {},
});

export const AdminPodcastProvider = ({ children }) => {
  const value = useAdminPodcast();
  return <AdminPodcastContext.Provider value={value}>{ children }</AdminPodcastContext.Provider>;
};
