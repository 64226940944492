import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useApi from '../../../utils/api';
import { defaultFormValues, formValidationConfig } from './config';
import { validateAll } from '../../../components/Form/config';

export default function usePasses() {
  const { authGet, authPut, authPost } = useApi();
  const [passes, setPasses] = useState([]);
  const [errors, setErrors] = useState();
  const [selectedPass, setSelectedPass] = useState();
  const [searchText, setSearchText] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [formLoading, setFormLoading] = useState(false);

  const getPasses = useCallback(
    async (search) => {
      try {
        setLoading(true);
        const res = await authGet(`/passes/lookup/${search}`);

        if (!res[0]) {
          toast.error(`No passes found with key: ${search}`);
        }

        setPasses(res);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [authGet],
  );

  const onPassClick = (_data) => {
    setFormLoading(false);

    if (_data) {
      setSelectedPass(_data);
    } else {
      setSelectedPass(defaultFormValues);
    }
  };

  const handleModalClose = () => {
    setSelectedPass();
    setErrors(null);
  };

  const submit = async (data) => {
    try {
      setFormLoading(true);
      const checkform = validateAll(data, formValidationConfig);
      if (checkform) {
        setErrors(checkform);
        setFormLoading(false);
      } else if (Number(data?.amount) > 1000) {
        setErrors({ amount: 'Amount Should not be over 1000!' });
        setFormLoading(false);
      } else if (data._id) {
        const _data = { ...data };
        _data.Points = Number(data.Points || 0);
        _data.Price = Number(data.Price || 0);
        _data.transferable = data.transferable || false;
        const res = await authPut(`/passes/${data._id}`, { data: _data });
        if (res) {
          toast.success(t('Pack Saved Successfully!'));
          handleModalClose();
          setFormLoading(false);
        }
      } else {
        const { passType, ...p } = data;
        const _data = { ...p };
        _data.transferable = !!data.transferable;
        _data.passTypeId = passType;
        const res = await authPost('/passes', { data: _data });
        if (res) {
          toast.success(t('Pack Saved Successfully!'));
          handleModalClose();
          setFormLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      setErrors({ memberId: t(err) });
      setFormLoading(false);
    }
  };

  const searchClick = useCallback(async () => {
    if (!searchText) {
      toast.error('Search Key is required.');
      return;
    }
    getPasses(searchText);
  }, [searchText, getPasses]);

  return {
    errors,
    setErrors,
    onPassClick,
    handleModalClose,
    submit,
    selectedPass,
    passes,
    setSearchText,
    searchText,
    searchClick,
    loading,
    formLoading,
  };
}
