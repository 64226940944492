export const formConfig = () => [
  {
    name: 'username',
    type: 'text',
    label: 'Username',
    placeholder: 'username', 
    subLabel: '( Name.memegames.ai )',
    format: (item) => item.trim().replace(' ', '').replace(/[^a-zA-Z0-9\s]/g, '').toLowerCase(),
  },
  
  {
    name: 'country',
    label: 'Country',
    type: 'ajaxSelect',
    endPoint: '/enums/countries',
    mapper: (val) => ({ label: val.Name, value: val.ID }),
  },
  {
    name: 'mobile',
    label: 'Phone Number',
    type: 'phone',
    placeholder: 'Phone Number',
  },
  {
    name: 'instagram',
    label: 'Instagram',
    type: 'text',
    placeholder: 'IG link or Account handler',
  },
  {
    name: 'isProduct',
    label: 'I would like to be a gamer at memegames.ai.',
    type: 'muiCheckbox',
    labelClassName: 'pt-3',
    underLabel: 'Become a gamer to play our daily Meme Coin games. You will be asked to use or purchase a game pack, which will give you MG Coins to play with.',
    underLabelStyle: { color: '#e0e0e0' },
  },
  {
    name: 'isAffiliate',
    label: 'I also wish to be an ambassador.',
    type: 'muiCheckbox',
    labelClassName: 'pt-3',
    underLabel: 'Join our ambassador program to sign up other gamers and ambassadors, create a team and earn commissions. ',
    underLabelStyle: { color: '#e0e0e0' },
  },
  {
    name: 'receiveNewsletter',
    label: 'I would like to sign up to receive the MemeGames newsletter and company updates.',
    type: 'muiCheckbox',
    labelClassName: 'pt-3',
  },

];

export const getFormConfig = () => formConfig;

export const formValidationConfig = {
  username: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  country: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' }, 
  mobile: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
};

// export default getFormConfig;
