import { createContext } from 'react';
import useBoosterLeadApi from './api';

export const BoosterLeadPageContext = createContext({
  t: () => {},
  user: {},

  fetchBoosterLeadData: () => {},
  boosterLeadDataColumnsConfiguration: [],
  boosterLeadData: [],
  loadedBoosterLeadData: false,

  boosterLeadEditData: {},
  setBoosterLeadEditData: () => {},
});

export const BoosterLeadPageProvider = ({ children }) => {
  const value = useBoosterLeadApi();

  return (
    <BoosterLeadPageContext.Provider value={value}>
      {children}
    </BoosterLeadPageContext.Provider>
  );
};
