import dayjs from 'dayjs';
import { renderNumber } from '../../../utils/string';

const scrollResultsSection = () => {
  const section = document.getElementsByClassName('game-single-result');
  section[0].scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  });
};

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
};

export const gameResultsColumnsConfig = (t, setGameSingleResultData) => [
  {
    field: 'startDate',
    headerName: t('Game Date'),
    flex: 1,
    minWidth: 100,
    ...defaultColConfig,
    renderCell: ({ value }) => dayjs(value).tz('UTC').format('DD MMM YYYY'),
  },
  {
    field: 'game',
    headerName: t('Game'),
    flex: 1,
    minWidth: 320,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <button
        className="show-single-game-button"
        onClick={() => {
          setGameSingleResultData(row);

          setTimeout(() => {
            scrollResultsSection();
          }, 300);
        }}
        type="button"
      >
        <span className={row.coin1 === row.winningCoin ? 'text-gold' : ''}>
          {row.coinOne?.name}
        </span>
        <span>
          VS.
        </span>
        <span className={row.coin2 === row.winningCoin ? 'text-gold' : ''}>
          {row.coinTwo?.name}
        </span>
      </button>
    ),
  },
  {
    field: 'price',
    headerName: t('Price'),
    flex: 1,
    minWidth: 100,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(value),
  },
  {
    field: 'votesCount',
    headerName: t('Total Votes'),
    flex: 1,
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(value),
  },
  {
    field: 'totalWagered',
    headerName: t('Coins Played'),
    flex: 1,
    minWidth: 140,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(value),
  },
  {
    field: 'earnedMgCoins',
    headerName: t('Coins Awarded'),
    flex: 1,
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(Math.ceil(value)),
  },
  {
    field: 'earnedTokens',
    headerName: t('Tokens Awarded'),
    flex: 1,
    minWidth: 140,
    ...defaultColConfig,
    renderCell: ({ value }) => renderNumber(value),
  },
];

export const gameSingleResultColumnsConfig = (t) => [
  {
    field: 'User',
    headerName: t('User'),
    flex: 1,
    minWidth: 250,
    ...defaultColConfig,
    renderCell: ({ value }) => (
      <span>
        {value?.Username}
        {' '}
        (
        {value?.rankName}
        )
      </span>
    ),
  },
  {
    field: 'memeCoin',
    headerName: t('Meme Coin'),
    flex: 1,
    minWidth: 100,
    ...defaultColConfig,
    renderCell: ({ value }) => <span>{value?.name}</span>,
  },
  {
    field: 'mgCoins',
    headerName: t('Coins'),
    minWidth: 120,
    ...defaultColConfig,
    renderCell: ({ value }) => <span>{renderNumber(Math.ceil(value))}</span>,
  },
  {
    field: 'coins',
    headerName: t('Coins Awarded'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => <span>{renderNumber(Math.ceil(value))}</span>,
  },
  {
    field: 'token',
    headerName: t('Tokens Awarded'),
    minWidth: 170,
    ...defaultColConfig,
    renderCell: ({ value }) => <span>{renderNumber(Math.ceil(value))}</span>,
  },
];
