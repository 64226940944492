import dayjs from 'dayjs';

export const defaultFormValues = {};

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

export const defaultBoosterLeadColumnsConfig = (t) => [
  {
    field: 'User',
    headerName: t('User'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => (
      <div
        className="flex w-full"
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <div className="flex">
          <span style={{ lineHeight: 'normal', marginRight: '5px' }}>
            {`${
              value.Country?.emoji || ''
            }`}
          </span>
          <span>{`${value.Username} (${value.SID})`}</span>
        </div>
      </div>
    ),
  },
  {
    field: 'UserType',
    headerName: t('Type'),
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ row }) => {
      const isAmbassador = row.User.isAffiliate;
      const isGamer = row.User.isProduct;
      const isBoth = isAmbassador && isGamer;

      const userType = `${isAmbassador ? 'Ambassador' : ''}${
        isBoth ? ' / ' : ''
      }${isGamer ? 'Gamer' : ''}`;
      return userType;
    },
  },
  {
    field: 'UserEmail',
    headerName: t('Email'),
    minWidth: 250,
    ...defaultColConfig,
    renderCell: ({ row }) => row.User.Email,
  },
  {
    field: 'createdAt',
    headerName: t('SignUp Date'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => dayjs(value).format('MMM DD, YYYY'),
  },
];
