import { renderNumber } from '../../../utils/string';

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
};

export const salesColumnsConfig = (t) => [
  {
    field: 'name',
    headerName: t('Name'),
    flex: 1,
    minWidth: 120,
    ...defaultColConfig,
  },
  {
    field: 'username',
    headerName: t('Username'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => value,
  },
  {
    field: 'passType',
    headerName: t('Pack Type'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => value,
  },
  {
    field: 'priceTotal',
    headerName: t('Amount'),
    flex: 1,
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => `$${renderNumber(value)}`,
  },
];

// export const salesColumnsConfig = (t) => [
//   {
//     field: 'paidPacks',
//     headerName: t('Paid Packs'),
//     flex: 1,
//     minWidth: 120,
//     ...defaultColConfig,
//   },
//   {
//     field: 'freeTrialPacks',
//     headerName: t('Free Trial Packs'),
//     flex: 1,
//     minWidth: 150,
//     ...defaultColConfig,
//     renderCell: ({ value }) => renderNumber(value),
//   },
//   {
//     field: 'mGCoinStarterPack',
//     headerName: t('MG Coin Starter Packs'),
//     flex: 1,
//     minWidth: 180,
//     ...defaultColConfig,
//     renderCell: ({ value }) => renderNumber(value),
//   },
//   {
//     field: 'boosterProgramPack',
//     headerName: t('Booster Program Packs'),
//     flex: 1,
//     minWidth: 200,
//     ...defaultColConfig,
//     renderCell: ({ value }) => renderNumber(value),
//   },
//   {
//     field: 'founderBundle',
//     headerName: t('Founder Bundles'),
//     flex: 1,
//     minWidth: 200,
//     ...defaultColConfig,
//     renderCell: ({ value }) => renderNumber(value),
//   },
//   {
//     field: 'directorBundle',
//     headerName: t('Director Bundles'),
//     flex: 1,
//     minWidth: 200,
//     ...defaultColConfig,
//     renderCell: ({ value }) => renderNumber(value),
//   },
//   {
//     field: 'possibleRealInvoices',
//     headerName: t('Possible Real Invoices'),
//     flex: 1,
//     minWidth: 200,
//     ...defaultColConfig,
//     renderCell: ({ value }) => renderNumber(value),
//   },
//   {
//     field: 'possibleMarkedInvoices',
//     headerName: t('Possible Marked Invoices'),
//     flex: 1,
//     minWidth: 220,
//     ...defaultColConfig,
//     renderCell: ({ value }) => renderNumber(value),
//   },
//   {
//     field: 'possibleRealAmount',
//     headerName: t('Possible Real Amount'),
//     flex: 1,
//     minWidth: 200,
//     ...defaultColConfig,
//     renderCell: ({ value }) => `$${renderNumber(value)}`,
//   },
//   {
//     field: 'total',
//     headerName: t('Total'),
//     flex: 1,
//     minWidth: 150,
//     ...defaultColConfig,
//     renderCell: ({ value }) => `$${renderNumber(value)}`,
//   },
// ];

export default {};
