const config = {
  viewBox: '0 0 19 19',
  children: (
    <>
      <path d="M14.0176 13.4637V15.8984C14.0176 17.003 13.1221 17.8984 12.0176 17.8984H3.01758C1.91301 17.8984 1.01758 17.003 1.01758 15.8984V2.89844C1.01758 1.79387 1.91301 0.898438 3.01758 0.898438H12.0176C13.1221 0.898438 14.0176 1.79387 14.0176 2.89844V5.14844" stroke="currentColor" />
      <path d="M5.99805 9.23242L10.0656 5.16488M5.99805 9.23242L10.0656 13.3M5.99805 9.23242H18.2848" stroke="currentColor" />
    </>
  ),
};

export default config;
