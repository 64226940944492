/* eslint-disable indent */
import {
  Grid, Pagination, Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GameHistoryContext, GameHistoryProvider } from './context';
import ComponentLoader from '../../components/ComponentLoader';
import GamesGroup from './components/GamesGroup';
import Select from '../../components/Inputs/Select';

const GameHistory = () => {
  const navigate = useNavigate();
  const {
    user,
    loadedData,
    gamesData,
    getPaginatedData,
    paginationData,
    tokens,
  } = useContext(GameHistoryContext);

  const [gameResultFilter, setGameResultFilter] = useState('default');
  const [gamePriceFilter, setGamePriceFilter] = useState('default');
  const [gameDaysFilter, setGameDaysFilter] = useState('default');
  const [page, setPage] = useState(paginationData?.page || 1);

  useEffect(() => {
    const filter = {};
    filter.page = page;

    if (gameResultFilter !== 'default') {
      filter.game = gameResultFilter;
    }

    if (gamePriceFilter !== 'default') {
      filter.price = gamePriceFilter;
    }

    if (gameDaysFilter !== 'default') {
      filter.days = gameDaysFilter;
    }

    getPaginatedData(filter);
  }, [gameResultFilter, gamePriceFilter, gameDaysFilter, page]);

  if (!user) {
    navigate('/landing');
  }

  return (
    <Grid id="game-history-page">
      <h1
        style={{
          fontWeight: 600,
          fontSize: '32px',
          textAlign: 'center',
          marginTop: '32px',
        }}
      >
        Historical Game List
      </h1>

      <Grid
        maxWidth="100%"
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
        className="game-history-panel"
      >
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          className="game-history-filters"
        >
          <div className="flex align-center">
            <b
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Won/Lost:
            </b>
            <Select
              name="transfers-filter-select"
              className="transfers-filter-select"
              placeholder="All games"
              size="sm"
              value={gameResultFilter}
              onChange={(v) => {
                setGameResultFilter(v);
              }}
              options={[
                { value: 'true', label: 'Won' },
                { value: 'false', label: 'Lost' },
              ]}
            />
          </div>
          <div className="flex align-center">
            <b
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Price:
            </b>
            <Select
              className="transfers-filter-select"
              placeholder="Any price"
              size="sm"
              value={gamePriceFilter}
              onChange={(v) => {
                setGamePriceFilter(v);
              }}
              options={[
                { value: '10', label: '10' },
                { value: '25', label: '25' },
                { value: '100', label: '100' },
              ]}
            />
          </div>
          <div className="flex align-center">
            <b
              style={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Date/Range:
            </b>
            <Select
              className="transfers-filter-select"
              placeholder="Show All"
              size="sm"
              value={gameDaysFilter}
              onChange={(v) => {
                setGameDaysFilter(v);
              }}
              options={[
                { value: '3', label: 'Last 3 days' },
                { value: '7', label: 'Last 7 days' },
                { value: '15', label: 'Last 15 days' },
                { value: '30', label: 'Last 30 days' },
                { value: '90', label: 'Last 90 days' },
              ]}
            />
          </div>
        </Grid>

        <Grid
          xl={12}
          lg={12}
          md={12}
          xs={12}
          sm={12}
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: 6,
            gap: 4,
          }}
          className="game-history-list"
        >
          {loadedData ? (
            <>
              {Object.entries(gamesData).length === 0
                && gameResultFilter === 'default'
                && gamePriceFilter === 'default' && (
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: '26px',
                      fontWeight: 600,
                      py: 4,
                      width: '100%',
                    }}
                  >
                    You haven’t joined any games yet!
                  </Typography>
                )}
              {Object.entries(gamesData).length === 0
                && (gameResultFilter !== 'default'
                  || gamePriceFilter !== 'default') && (
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: '26px',
                      fontWeight: 600,
                      py: 4,
                      width: '100%',
                    }}
                  >
                    No games under this conditions.
                  </Typography>
                )}
              {Object.entries(gamesData).map((games, index) => (
                <GamesGroup
                  date={games[0]}
                  games={games[1]}
                  key={index}
                  userTokens={tokens}
                />
              ))}

              {!!paginationData && paginationData?.totalUsers > 10 && (
                <div className="flex justify-end pagination-container">
                  <Typography sx={{ mt: 0.5 }}>
                    {`Total of: ${paginationData.totalUsers} Games`}
                  </Typography>
                  <Pagination
                    count={paginationData?.total}
                    page={paginationData?.page}
                    onChange={(val, pageNumber) => {
                      setPage(pageNumber);
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <ComponentLoader />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default () => (
  <GameHistoryProvider>
    <GameHistory />
  </GameHistoryProvider>
);
