import { Typography } from '@mui/material';
import { useState } from 'react';

const TreeColorsKey = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="tree-colors-key-container">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        title="Colors definition"
      >
        <span>?</span>
      </button>
      {isOpen && (
        <div className="tree-colors-definition">
          <Typography className="row">
            <span className="color color--grey" />
            <span className="name">Never Played/New user</span>
          </Typography>
          <Typography className="row">
            <span className="color color--blue" />
            <span className="name">Free Trial</span>
          </Typography>
          <Typography className="row">
            <span className="color color--green" />
            <span className="name">Active</span>
          </Typography>
          <Typography className="row">
            <span className="color color--red" />
            <span className="name">Inactive</span>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TreeColorsKey;
