import {
  useCallback, useContext, useEffect, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';

const PAGE_SIZE = 25;

const usePodcast = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const { authGet } = useApi();
  const [podcastsData, setPodcastsData] = useState([]);
  const [podcastsDataLoaded, setPodcastsDataLoaded] = useState(false);
  const [paginationData, setPaginationData] = useState();

  const getPodcastsData = useCallback(
    async ({ page = 1 }) => {
      try {
        setPodcastsDataLoaded(false);
        const response = await authGet('/podcast', {
          params: { page, perPage: PAGE_SIZE, published: true },
        });
        setPodcastsData(response.data || []);

        setPaginationData({
          totalPodcasts: response.total,
          total: Math.ceil(response.total / PAGE_SIZE),
          page,
        });
      } catch (err) {
        console.log(err);
        toast.error('Error fetching podcast Data');
      }
      setPodcastsDataLoaded(true);
    },
    [authGet],
  );

  useEffect(() => {
    getPodcastsData({});
  }, []);

  return {
    t,
    user,

    getPodcastsData,

    podcastsData,
    podcastsDataLoaded,

    paginationData,
  };
};

export default usePodcast;
