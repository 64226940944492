/* eslint-disable no-restricted-globals */
export const renderNumber = (val) => {
  if (typeof val === 'number') {
    return val.toLocaleString('en-us');
  }
  if (!isNaN(val)) {
    return Number(val).toLocaleString('en-us');
  } 
  return 0;
};

export const renderPrice = (val) => {
  const n = Number(val).toString();
  if (n.includes('e-')) {
    const [a, b] = n.split('e-');
    const zeros = '0'.repeat(+b).toString();
    const nums = a.split('.').join('');
    const nume = `0.${zeros}${nums}`;
    return nume;
  }
  return n;
};

export default null;
