import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import App from './App';
import AppLoader from './components/AppLoader';
import reportWebVitals from './reportWebVitals';
import { StyleProvider } from './utils/store/style';
import { GeneralProvider } from './utils/store/general';
import { AuthenticationProvider } from './utils/store/authentication';
import { AppProvider } from './utils/store/app';
import { SocketContext, socket } from './utils/store/socket';

import './i18n';
import './style/_index.scss';
import 'react-toastify/dist/ReactToastify.css';
import StagingBanner from './components/StagingBanner';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GeneralProvider>
    <StyleProvider>
      <AuthenticationProvider>
        <SocketContext.Provider value={socket}>
          <AppProvider>
            <Suspense fallback={<AppLoader />}>
              <StagingBanner />
              <App />
              <ToastContainer theme="dark" />
            </Suspense>
          </AppProvider>
        </SocketContext.Provider>
      </AuthenticationProvider>
    </StyleProvider>
  </GeneralProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
