import { useContext } from 'react';
import { Typography } from '@mui/material';
import { AdminPodcastContext } from '../context';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

const DeletePodcastModal = () => {
  const {
    t, podcastDelete, handleCloseDeleteModal, handleSubmitDeleteModal, 
  } = useContext(AdminPodcastContext);

  return (
    <Modal
      title="Delete podcast"
      open={!!podcastDelete}
      handleClose={handleCloseDeleteModal}
      width="600px"
    >
      <Typography fontSize="16px" fontWeight="bold" textAlign="center" marginBottom="8px">
        {t('Are you sure you want to Delete this Podcast?')}
      </Typography>
      <Typography textAlign="center" marginBottom="32px">{podcastDelete?.title}</Typography>

      <Button onClick={handleSubmitDeleteModal}>{t('Delete')}</Button>
    </Modal>
  );
};

export default DeletePodcastModal;
