import { Grid } from '@mui/material';
import { renderNumber } from '../../../../utils/string';

const ResultsBox = ({ title, result }) => (
  <Grid item className="results-box">
    <div className="title">
      <span>{title}</span>
    </div>
    <div className="result">
      <span>{renderNumber(Math.ceil(result))}</span>
    </div>
  </Grid>
);

export default ResultsBox;
