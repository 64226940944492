import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import { getFormConfig } from './config';
import Form from '../../../../components/Form';
import usePurchasePackage from './hook';
// import StripeModal from './components/stripeModal'; // stripe
import BtcModal from './components/modal';

export default function PurchasePackages({ reset }) {
  const { t } = useTranslation();
  const {
    submitPurchase, errors, setErrors, purchaseData,
    handleModalClose, defaultValue, packages,
  } = usePurchasePackage(reset);

  return (
    <div>
      {
        packages && packages[0] ? (
          <div>
            <h2>{t('Purchase Bundles')}</h2>

            <Form
              size="sm"
              config={getFormConfig()}
              onSubmit={submitPurchase}
              submitLabel="Purchase"
              defaultFormValue={defaultValue}
              errors={errors}
              setErrors={setErrors}
              buttonType="submit"
              buttonStyle={{ minWidth: '182px' }}
            />
            {purchaseData && (
              <BtcModal
                data={purchaseData}
                handleModalClose={handleModalClose}
              />
            )}
          </div>
        ) : (
          <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography sx={{
              fontSize: '13px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', color: 'gray',
            }}
            >
              {' '}
              No packages
            </Typography>
          </Container>
        )
      }

    </div>
  );
}
