import dayjs from 'dayjs';

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',

  cellClassName: () => 'first-column',
  // eslint-disable-next-line no-confusing-arrow
  renderCell: ({ value }) => !Number.isNaN(value) ? value.toLocaleString('en-us') : value,
};

export const gamercolumnsConfig = (t) => [
  {
    field: 'value',
    headerName: t('AMOUNT'),
    minWidth: 120,
    ...defaultConfig,
    renderCell: ({ row }) => (
      <span className={row.direction}>{row.value.toLocaleString('en-us')}</span>
    ),
  },
  {
    field: 'type',
    headerName: t('Type'),
    minWidth: 130,
    ...defaultConfig,
  },
  {
    field: 'description',
    minWidth: 500,
    headerName: `${t('DESCRIPTION')}`,
    ...defaultConfig,
    flex: 1,
    renderCell: ({ value }) => t(`${value}`),
  },
  {
    field: 'createdAt',
    headerName: t('DATE'),
    minWidth: 250,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('lll'),
  },
];

export const ambassadorcolumnsConfig = (t) => [
  {
    field: 'amount',
    headerName: t('AMOUNT RECEIVED / DEDUCTED'),
    minWidth: 270,
    ...defaultConfig,
    renderCell: ({ value }) => `$${!Number.isNaN(value) ? value.toLocaleString('en-us') : value}`,
  },
  {
    field: 'description',
    minWidth: 630,
    headerName: `${t('DESCRIPTION')}`,
    ...defaultConfig,
    renderCell: ({ value }) => t(`${value}`),
  },
  {
    field: 'createdAt',
    headerName: t('DATE'),
    minWidth: 200,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('lll'),
  },
];

export const transferscolumnsConfig = (t) => [
  {
    field: 'token',
    headerName: t('Token RECEIVED / DEDUCTED'),
    minWidth: 270,
    ...defaultConfig,
  },
  {
    field: 'description',
    minWidth: 630,
    headerName: `${t('DESCRIPTION')}`,
    ...defaultConfig,
    renderCell: ({ value }) => t(`${value}`),
  },
  {
    field: 'createdAt',
    headerName: t('DATE'),
    minWidth: 200,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('lll'),
  },
];
export const modalTypes = {
  WITHDRAW: 'withdraw',
  TRANSFER: 'transfer',
  CONFIRMSTION: 'confirmation',
  COMMITMENT: 'commitment',
};

export const withdrawFormConfig = [
  {
    name: 'amount',
    type: 'number',
    label: 'Amount (BTC)',
  },
  {
    name: 'wallet',
    type: 'text',
    label: 'BTC Wallet',
  },
];

export const transferFormConfig = [
  {
    name: 'tokens',
    label: 'Tokens',
    type: 'number',
  },
  {
    name: 'userId',
    label: 'User',
    type: 'autocomplete',
    endPoint: '/users?isProduct=true', // TODO: add endpoint
    mapper: (val) => ({ label: `${val.Name} ${val.Surname} (${val.Username})`, value: val }), // TODO: add mapper
  },

];

export const withdrawFormValidationConfig = {
  amount: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  wallet: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
};

export default {};
