import {
  useCallback, useContext, useEffect, useMemo, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import useApi from '../../../utils/api';
import { AuthenticationContext } from '../../../utils/store/authentication';
import { defaultUsageReportColumnsConfig } from '../UsageReport/config';
import { salesColumnsConfig } from '../SalesReport/config';
import { browserDownloadBlobData } from '../../../utils/file';
import { gameResultsColumnsConfig, gameSingleResultColumnsConfig } from '../GameResultsReport/config';
import { webinarLeadsReportColumnsConfig } from '../WebinarLeadsReport/config';

const PAGE_SIZE = 15;

const useAdminReportApi = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const [usageData, setUsageData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [usageDataPagination, setUsageDataPagination] = useState();
  const [loadedUsageData, setLoadedUsageData] = useState(false);
  const [loadedSalesData, setLoadedSalesData] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);

  const [gameResultsData, setGameResultsData] = useState([]);
  const [gameResultsDataLoaded, setGameResultsDataLoaded] = useState(false);
  const [gameResultsDataPagination, setGameResultsDataPagination] = useState();

  const [gameResultsCondensedData, setGameResultsCondensedData] = useState([]);
  const [gameResultsCondensedDataLoaded, setGameResultsCondensedDataLoaded] = useState(false);
  
  const [gameSingleResultData, setGameSingleResultData] = useState(null);

  const [webinarLeadsData, setWebinarLeadsData] = useState([]);
  const [webinarLeadsDataLoaded, setWebinarLeadsDataLoaded] = useState(false);

  const { authGet } = useApi();

  const fetchUsageData = useCallback(
    async ({ page = 1 }) => {
      setLoadedUsageData(false);

      const response = await authGet('/reports/usage', {
        params: {
          page, perPage: PAGE_SIZE,
        },
      });

      setUsageData(response.data);

      setUsageDataPagination({
        totalUsers: response.total,
        total: Math.ceil(response.total / PAGE_SIZE),
        page,
      });

      setLoadedUsageData(true);
    },
    [authGet],
  );

  const fetchSalesData = useCallback(
    async ({ page = 1 }) => {
      setLoadedSalesData(false);

      const response = await authGet('/reports/sales-report', {
        params: {
          page, perPage: PAGE_SIZE,
        },
      });
      setSalesData(response);

      setLoadedSalesData(true);
    },
    [authGet],
  );

  const fetchGameResultsData = useCallback(
    async ({ page = 1 }) => {
      setGameResultsDataLoaded(false);
      
      const response = await authGet('/reports/game-results', {
        params: {
          page, perPage: PAGE_SIZE,
        },
      });

      setGameResultsData(response.data);

      setGameResultsDataPagination({
        totalGames: response.total,
        total: Math.ceil(response.total / PAGE_SIZE),
        page,
      });

      setGameResultsDataLoaded(true);
    },
    [authGet],
  );

  const fetchGameResultsCondensedData = useCallback(
    async (filter = {}) => {
      setGameResultsCondensedDataLoaded(false);
      const response = await authGet('reports/game-results/condensed', { params: filter });
      setGameResultsCondensedData(response.data || []);
      setGameResultsCondensedDataLoaded(true);
    },
    [authGet],
  );

  const downloadUsageReportCsv = useCallback(
    async () => {
      if (!downloadingCsv) {
        setDownloadingCsv(true);
        const responseData = await authGet('/reports/usage/csv');

        browserDownloadBlobData(responseData, 'usage_report.csv');

        setDownloadingCsv(false);
      }
    },
    [authGet, downloadingCsv],
  );

  const fetchWebinarLeadsData = useCallback(
    async () => {
      setWebinarLeadsDataLoaded(false);

      const response = await authGet('reports/webinar');

      setWebinarLeadsData(response.data);

      setWebinarLeadsDataLoaded(true);
    },
    [authGet],
  );

  useEffect(() => {
    fetchUsageData({});
    fetchSalesData({});
  }, []);

  // Columns Configuration ===================================
  const usageDataColumnsConfiguration = useMemo(() => defaultUsageReportColumnsConfig(t), [t]);
  const salesColumnsConfiguration = useMemo(() => salesColumnsConfig(t), [t]);
  const gameResultsColumnsConfiguration = useMemo(() => gameResultsColumnsConfig(t, setGameSingleResultData), [t, setGameSingleResultData]);
  const gameSingleResultColumnsConfiguration = useMemo(() => gameSingleResultColumnsConfig(t), [t]);
  const webinarLeadsColumnsConfiguration = useMemo(() => webinarLeadsReportColumnsConfig(t), [t]);
  // Columns Configuration ===================================

  return {
    t,
    user,
    usageData,
    loadedUsageData,
    usageDataPagination,
    fetchUsageData,
    fetchSalesData,
    salesData,
    loadedSalesData,
    usageDataColumnsConfiguration,
    salesColumnsConfiguration,
    downloadingCsv,
    downloadUsageReportCsv,
    
    fetchGameResultsData,
    gameResultsColumnsConfiguration,
    gameResultsData,
    gameResultsDataLoaded,
    gameResultsDataPagination,

    fetchGameResultsCondensedData,
    gameResultsCondensedData,
    gameResultsCondensedDataLoaded,
    setGameSingleResultData,
    gameSingleResultData,
    gameSingleResultColumnsConfiguration,

    fetchWebinarLeadsData,
    webinarLeadsData,
    webinarLeadsDataLoaded,
    webinarLeadsColumnsConfiguration,
  };
};

export default useAdminReportApi;
