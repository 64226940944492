/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import {
  Typography, Grid,
} from '@mui/material';
import { useContext, useState } from 'react';
import Layout from '../../../components/CardLayout';
import { Context } from '../context';
import TextInput from '../../../components/Inputs/TextInput';
import Button from '../../../components/Button';
import ClockTimer from '../../../components/ClockTimer';
import { modalTypes } from '../config';
import BtcWalletNumber from './btcWallet';
import Modal from '../../../components/Modal';
import PurchasePasses from '../../Memberships/components/PurchasePasses';
import PurchasePackages from '../../Memberships/components/PurchasePackages';
import SolanaWallet from './solanaWallet';

export const getDisclaimerContent = () => (
  <Typography sx={{ fontSize: '11px', color: 'GrayText', textTransform: 'uppercase' }}>
    Be careful!
    {' '}
    <a href="https://memegames.ai/" target="_blank" rel="noreferrer">
      MemeGames.ai
    </a>
    {' '}
    is not responsible for any lost funds sent to an incorrect address.
  </Typography>
);

export default function WalletManagement() {
  const {
    t,
    defaultWallet,
    setDefaultWallet,
    setModalData,
  } = useContext(Context);
  const [openModal, setOpenModal] = useState(false);

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const handleClosePopUpModal = () => {
    setPopupIsOpen(false);
    setModalData({
      type: modalTypes.WITHDRAW, value: 0, setValue: setDefaultWallet, description: t('Redeem'),
    });
  };

  return (
    <Layout maxWidth="xl" sx={{ marginTop: 4, padding: { xs: 0, lg: '0 24px' } }}>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: { xs: '21px', lg: '26px' },
          fontWeight: 600,
          mb: 1,
          textAlign: 'center',
        }}
      >
        {t('Ambassador Wallets')}
      </Typography>
      <Grid item display="flex" justifyContent="center" xs={12}>
        <ClockTimer prefix="Time until daily processing: " countdown />
      </Grid>
      <Modal
        title="Redeem"
        width="550px"
        open={popupIsOpen}
        handleClose={() => { handleClosePopUpModal(); }}
      >
        <p className="mb-8 text-center">
          Want to speed up the process? Reduce processing fees? Consider redeeming for coin packs for new sign ups or renewals, since you can collect the money instantly from your team for the coin packs you just redeemed immediately. And the best part is that there is no limit to how many times a day you can withdraw for coin packs!
        </p>
        <Button onClick={handleClosePopUpModal} bordered>Close to Continue</Button>
      </Modal>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginY: 4 }}>
          <TextInput
            className="wallet-input"
            value={defaultWallet?.balance?.toLocaleString('en-us') || '0'}
            onChange={(e) => {
              setDefaultWallet(e);
            }}
            readOnly
            startAdornment={(
              <span className="flex items-center">
                <Typography sx={{
                  pl: 2, opacity: 0.5, fontWeight: 500, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                }}
                >
                  {defaultWallet.currency}
                </Typography>
              </span>
            )}
            endAdornment={(
              <div className="flex items-center">
                <Button
                  onClick={() => setPopupIsOpen(true)}
                  className="py-auto my-auto mr-3"
                  bordered={(defaultWallet?.balance || 0) < 135}
                  size="sm"
                >
                  <span className="no-wrap">{t('Redeem')}</span>
                </Button>
              </div>
            )}
          />
          <Typography sx={{
            padding: 1, fontSize: '14px', opacity: 0.5, textAlign: { xs: 'center' },
          }}
          >
            {t('Withdrawals are in $135 increments. Please allow 5 full business days for redemption in Solana. You may chose to withdraw in coin packs which are available immediately')}
          </Typography>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>

        <SolanaWallet showMessage={false} />
      </Grid>
      
      <BtcWalletNumber />

      <Modal
        open={openModal}
        width="500px"
        title="Purchase Coins"
        handleClose={() => {
          setOpenModal(false);
        }}
      >
        <Grid item xs={12} marginBottom={6}>
          <PurchasePasses />
        </Grid>

        <Grid item xs={12}>
          <PurchasePackages />
        </Grid>
      </Modal>
    </Layout>
  );
}
