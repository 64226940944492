export const bannerFormConfig = [
  {
    name: 'title',
    type: 'text',
    label: 'Title',
    placeholder: 'Banner title',
    required: true,
  },
  {
    name: 'publicUntil',
    type: 'date',
    label: 'Public Until Date',
    required: true,
  },
  {
    name: 'message',
    type: 'textarea',
    label: 'Message',
    placeholder: 'Type your message',
    required: true,
    rows: 6,
  },
];

export const bannerFormValidationConfig = {
  title: { isEmpty: false, type: 'String', message: 'Title is required' },
  message: { isEmpty: false, type: 'String', message: 'Message is required' },
  publicUntil: { isEmpty: false, type: 'Date', message: 'Availability Date is required' },
};
