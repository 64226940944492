/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

export default function UserNode({ user, currentUser }) {
  return (
    <div style={{ position: 'relative' }}>
      <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }}>{user.username}</Typography>
      <Typography sx={{ fontSize: '12px', color: 'gray' }}>{user.displayName}</Typography>
      {currentUser?.SID === user.enrolledParentId && (
        <Typography
          variant="span"
          sx={{
            width: '5px',
            height: '5px',
            position: 'absolute',
            right: '-15px',
            top: '0',
            backgroundColor: 'var(--primary-color)',
            borderRadius: '50%',
          }}
        />
      )}
      {(user?.isProduct) && (
        <Typography sx={{ fontSize: '12px', color: 'gray' }}>
          {`${dayjs(user?.dateExpiring).tz('UTC').format('MMM DD, YYYY')}`}
        </Typography>
      )}
      {/* <Typography sx={{ fontSize: '12px', color: 'gray' }}>
        Sponsor:
        {user.enrolledParentId || currentUser?.enrolledParentId}
      </Typography> */}
      {/* <Typography sx={{ fontSize: '12px', color: 'gray' }}>
        ParentID:
        {user.parentId || currentUser?.parentId}
      </Typography> */}
      {/* <Typography sx={{ fontSize: '12px', color: 'white' }}>
        {`teamIds:- ${user.team1Id}, ${user.team2Id}, ${user.team3Id}`}
      </Typography> */}
      {/* <Typography sx={{ fontSize: '12px', color: 'white' }}>
        {`st:- ${user.sponsorTeams}`}
      </Typography> */}
    </div>
  );
}
