import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import {
  Container, Grid, Pagination, Typography, 
} from '@mui/material';
import { AdminPodcastContext, AdminPodcastProvider } from './context';
import Layout from '../../../components/CardLayout';
import Button from '../../../components/Button';
import AddEditPodcastModal from './components/AddEditPodcastModal';
import PodcastRow from './components/PodcastRow';
import ComponentLoader from '../../../components/ComponentLoader';
import DeletePodcastModal from './components/DeletePodcastModal';

const AdminPodcastPage = () => {
  const {
    t,
    user,
    getPodcastsData,
    handleOpenModalToAdd,
    podcastsData,
    podcastsDataLoaded,
    paginationData,
  } = useContext(AdminPodcastContext);

  const navigate = useNavigate();

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container maxWidth="xl" className="admin-podcast-page">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          {t('Podcast Administration')}
        </Typography>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingTop: '14px' }}>
          <Button size="sm" onClick={handleOpenModalToAdd}>
            <span>Add Podcast</span>
          </Button>
        </Grid>
        
        <AddEditPodcastModal />
        <DeletePodcastModal />
      </Layout>

      {!podcastsDataLoaded && (
        <Layout
          maxWidth="100%"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: 6,
          }}
        >
          <ComponentLoader />
        </Layout>
      )}

      {(podcastsDataLoaded && podcastsData.length > 0) && (
        <Layout
          maxWidth="100%"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: 6,
          }}
        >
          {podcastsData.map((podcast, idx) => <PodcastRow podcast={podcast} key={idx} />)}
        </Layout>
      )}

      {(paginationData && paginationData.totalPodcasts > 25) && (
        <div className="flex justify-end mt-4">
          <Typography sx={{ mt: 0.5 }}>
            {`Total of: ${paginationData.totalPodcasts} episodes`}
          </Typography>
          <Pagination
            count={paginationData?.total}
            page={paginationData?.page}
            onChange={(val, page) => {
              getPodcastsData({ page });
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default () => (
  <AdminPodcastProvider>
    <AdminPodcastPage />
  </AdminPodcastProvider>
);
