/* eslint-disable react/no-danger */
import { useContext, useState } from 'react';
import Modal from '../../../../components/Modal';
import { GlobalBannerContext } from '../context';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Banner from '../../../../components/Banner';

const AddEditBannerModal = () => {
  const {
    t,
    bannerData,
    formSubmitting,
    formErrors,
    setFormErrors,
    handleFormSubmit,
    handleOpenModal,
    handleCloseModal,
    bannerFormConfiguration,
  } = useContext(GlobalBannerContext);

  const [dangerHtml, setDangerHtml] = useState(null);

  return (
    <>
      <Button size="sm" onClick={handleOpenModal}>
        <span>Add Banner</span>
      </Button>
      <Modal
        title={
          bannerData?.id ? t('Edit Banner Message') : t('Add Banner Message')
        }
        width="100%"
        open={!!bannerData}
        handleClose={handleCloseModal}
        modalStyle={{ maxWidth: '1250px', margin: '0 auto' }}
      >
        <Form
          defaultFormValue={bannerData}
          config={bannerFormConfiguration}
          onSubmit={handleFormSubmit}
          setCurrentData={({ message }) => setDangerHtml(message)}
          formLoading={formSubmitting}
          buttonType="button"
          buttonStyle={{ width: '100%', marginTop: 0 }}
          errors={formErrors}
          setErrors={setFormErrors}
          submitLabel={t('Save')}
        />

        {dangerHtml && (
          <Banner
            data={{
              message: (
                <span dangerouslySetInnerHTML={{ __html: dangerHtml }} />
              ),
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default AddEditBannerModal;
