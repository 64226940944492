/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import {
  Container, Grid,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { AdminReportContext, AdminReportProvider } from '../context';
import Layout from '../../../components/CardLayout';
import ComponentLoader from '../../../components/ComponentLoader';
import DateGroups from './components/DateGroup';

const SalesReportPage = () => {
  const {
    t,
    salesData,
    loadedSalesData,
    salesColumnsConfiguration,
  } = useContext(AdminReportContext);
  const grandTotal = (salesData?.grandTotal && salesData?.grandTotal.length > 0) ? salesData?.grandTotal[0] : 0;

  return (
    <Container maxWidth="xl" className="sales-report-page">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          {t('Sales Report')}
        </Typography>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'left',
            fontSize: '20px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          {`${t('Total Sales')} ${grandTotal.totalCount || 0}`}

        </Typography>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'left',
            fontSize: '20px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          {`${t('Amount')} $${grandTotal?.total?.toLocaleString('en') || 0}`}

        </Typography>
      </Layout>

      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        {loadedSalesData ? (
          <DateGroups
            items={salesData}
            salesColumnsConfiguration={salesColumnsConfiguration}
            loadedSalesData={loadedSalesData}
          />
        ) : (
          <ComponentLoader />
        )}
      </Grid>
    </Container>
  );
};

export default () => (
  <AdminReportProvider>
    <SalesReportPage />
  </AdminReportProvider>
);
