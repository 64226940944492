import { useContext, useEffect, useState } from 'react';
import {
  Container, Grid, Pagination, Typography, 
} from '@mui/material';
import { toast } from 'react-toastify';
import { AdminReportContext, AdminReportProvider } from '../context';
import Layout from '../../../components/CardLayout';
import Table from '../../../components/Table';
import DateInput from '../../../components/Inputs/DatePicker';
import ComponentLoader from '../../../components/ComponentLoader';
import ResultsBox from './components/ResultsBox';
import GameSingleResult from './components/GameSingleResult';

const GameResultsReport = () => {
  const {
    t,
    fetchGameResultsData,
    gameResultsColumnsConfiguration,
    gameResultsData,
    gameResultsDataLoaded,
    gameResultsDataPagination,

    fetchGameResultsCondensedData,
    gameResultsCondensedData,
    gameResultsCondensedDataLoaded,
  } = useContext(AdminReportContext);

  const [globalDateFrom, setGlobalDateFrom] = useState(null);
  const [globalDateTo, setGlobalDateTo] = useState(null);

  useEffect(() => {
    fetchGameResultsData({});
    fetchGameResultsCondensedData();
  }, []);

  useEffect(() => {
    if (!!globalDateTo && globalDateTo < globalDateFrom) {
      setGlobalDateTo(null);
      toast.error('DateFrom must be greater than DateTo');
    }
    
    const filter = {};

    if (globalDateFrom && globalDateFrom.isValid()) {
      filter.dateFrom = globalDateFrom;
    }

    if (globalDateTo && globalDateTo.isValid()) {
      filter.dateTo = globalDateTo;
    }
    
    fetchGameResultsCondensedData(filter);
  }, [globalDateFrom, globalDateTo]);

  return (
    <Container maxWidth="xl" className="game-results-page">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            textAlign: 'center',
            mb: 1,
            width: '100%',
          }}
        >
          {t('Game Results Report')}
        </Typography>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>

        <Grid
          container
          display="flex"
          alignItems="center"
          gap="16px"
          marginBottom="16px"
        >
          <div className="flex flex-column">
            <span>
              <b>{!globalDateTo ? 'Game Date: ' : 'From: '}</b>
              {globalDateFrom && (
                <button
                  type="button"
                  className="reset-date-button"
                  onClick={() => {
                    setGlobalDateFrom(null);
                    setGlobalDateTo(null);
                  }}
                >
                  x
                </button>
              )}
            </span>
            <DateInput
              value={globalDateFrom}
              onChange={setGlobalDateFrom}
              size="sm"
              focused
            />
          </div>
          <div className="flex flex-column">
            <span>
              <b>To: </b>
              {globalDateTo && (
                <button
                  type="button"
                  className="reset-date-button"
                  onClick={() => {
                    setGlobalDateTo(null);
                  }}
                >
                  x
                </button>
              )}
            </span>
            <DateInput
              value={globalDateTo}
              onChange={setGlobalDateTo}
              size="sm"
              focused
              disabled={!globalDateFrom}
            />
          </div>
        </Grid>

        {gameResultsCondensedDataLoaded ? (
          <Grid container className="results-container" paddingTop="16px">
            <ResultsBox
              title="Total Games"
              result={gameResultsCondensedData.count}
            />
            <ResultsBox
              title="Total Votes"
              result={gameResultsCondensedData.votes}
            />
            <ResultsBox
              title="MG Coins Played"
              result={gameResultsCondensedData.coinsWagered}
            />
            <ResultsBox
              title="MG Coins Awarded"
              result={gameResultsCondensedData.coinsEarned}
            />
            <ResultsBox
              title="MG Tokens Awarded"
              result={gameResultsCondensedData.tokensEarned}
            />
          </Grid>
        ) : (
          <ComponentLoader />
        )}
      </Layout>

      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        {gameResultsDataLoaded ? (
          <>
            <Table
              loading={!gameResultsDataLoaded}
              columns={gameResultsColumnsConfiguration}
              rows={gameResultsData}
              className="table"
              showPagination
            />
            {!!gameResultsDataPagination
              && gameResultsDataPagination?.totalGames > 15 && (
                <div className="flex justify-end mt-4">
                  <Typography sx={{ mt: 0.5 }}>
                    {`Total of: ${gameResultsDataPagination.totalGames} games`}
                  </Typography>
                  <Pagination
                    count={gameResultsDataPagination?.total}
                    page={gameResultsDataPagination?.page}
                    onChange={(val, page) => {
                      fetchGameResultsData({ page });
                    }}
                  />
                </div>
            )}
          </>
        ) : (
          <ComponentLoader />
        )}
      </Grid>

      <GameSingleResult />
    </Container>
  );
};

export default () => (
  <AdminReportProvider>
    <GameResultsReport />
  </AdminReportProvider>
);
