import useRedeemTokenApi from './api';

const useRedeemToken = () => {
  const {
    t,
    user,

    loadedGamesData,
    gamesData,

    loadedWalletData,
    walletData,

    fetchRedeemData,

    handleRedeemCoinPackSubmit,
  } = useRedeemTokenApi();

  return {
    t,
    user,

    loadedGamesData,
    gamesData,

    loadedWalletData,
    walletData,

    fetchRedeemData,

    handleRedeemCoinPackSubmit,
  };
};

export default useRedeemToken;
