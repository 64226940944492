import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Button from '../../Button';
import { PAGE_SIZE } from '../../../features/Memberships/api';

export default function TablePagination({
  page = 1,
  count = 1,
  isMobile = false,
  onChange = () => { },
}) {
  const goPrev = () => {
    if (page === 1) {
      return;
    }

    onChange(page - 1);
  };

  const goNext = () => {
    if (page === count || !count) {
      return;
    }

    onChange(page + 1);
  };

  if (isMobile) {
    return (
      <div className="table-pagination flex justify-center">
        <div className="flex flex-column justify-center mr-3">
          <Button bordered size="" onClick={goPrev}>
            <div className="pl-1 pr-1 pt-1">
              <ChevronLeft fontSize="medium" />
            </div>
          </Button>
        </div>

        <div className="flex flex-column justify-center mr-3">
          <span>
            {((page - 1) * PAGE_SIZE) + 1}
            -
            {page * PAGE_SIZE}
            {' '}
            of
            {' '}
            {count * PAGE_SIZE}
          </span>
        </div>

        <div className="flex flex-column justify-center">
          <Button bordered size="" onClick={goNext}>
            <div className="pl-1 pr-1 pt-1">
              <ChevronRight fontSize="medium" />
            </div>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="table-pagination flex">
      <div className="flex flex-column justify-center mr-3">
        <span className="mr-7">
          {((page - 1) * PAGE_SIZE) + 1}
          -
          {page * PAGE_SIZE}
          {' '}
          of
          {' '}
          {count * PAGE_SIZE}
        </span>
      </div>

      <div className="flex flex-column justify-center mr-3">
        <Button bordered size="" onClick={goPrev}>
          <div className="pl-1 pr-1 pt-1">
            <ChevronLeft fontSize="medium" />
          </div>
        </Button>
      </div>

      <div className="flex flex-column justify-center">
        <Button bordered size="" onClick={goNext}>
          <div className="pl-1 pr-1 pt-1">
            <ChevronRight fontSize="medium" />
          </div>
        </Button>
      </div>
    </div>
  );
}
