/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import { Box, Grid, Typography } from '@mui/material';
import {
  CategoryScale, Chart as ChartJS, LinearScale, PointElement, LineElement,
} from 'chart.js';
import {
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import clsx from 'clsx';
import { Context } from '../../../context';
import Button from '../../../../../components/Button';
import { renderNumber } from '../../../../../utils/string';
import Icon from '../../../../../components/Icon';
import Modal from '../../../../../components/Modal';
import CardChip from '../CardChip';
import { defaultTheme } from '../../../../../constants/theme';
import TradingViewWidget from './TradingViewWidget';
import RedeemButton from '../../../../../components/RedeemButton';
import AIData from './AIData';
import { SocketContext } from '../../../../../utils/store/socket';
import Price from '../../../../../components/Price';
import LockButton from '../LockButton';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

export const renderPercentage = (val, withColor = true) => {
  const _percentage = Number(val).toFixed(2);

  if (withColor) {
    return (
      <span className="value" style={{ color: _percentage > 0 ? defaultTheme.SUCCESS : null }}>
        {Math.ceil(_percentage)}
        %
        (
        {_percentage}
        %)
      </span>
    );
  }

  return (
    <span className="value">
      {val < 1 ? val : _percentage}
      %
    </span>
  );
};

export default function VoteCoin({
  coinData,
  coinId,
  isYesterday, size = 6,
  index = 0, userTokens,
  todayGame, isMobile,
  username,
  currentVote,
}) {
  const {
    onChooseMemeCoin, chosenYesterdayMemeCoin: chosenYesterdayMemeCoins,
    chosenMemeCoin: chosenMemeCoins,
  } = useContext(Context);
  const socket = useContext(SocketContext);

  const [chartModal, setChartModal] = useState(false);
  const [coin, setCoin] = useState(coinData);
  const [priceStyle, setPriceStyle] = useState('primary-color');
  const [votes, setVotes] = useState(coinData?.votes || 0);

  let chosenMemeCoin;
  let chosenYesterdayMemeCoin;

  useEffect(() => {
    if (todayGame && !isYesterday) {
      socket.on(`coin:updated:${todayGame.id}:${coinId}`, (data) => {
        const lastPrice = Number(coin?.data?.market_data?.current_price?.usd);
        const currentPrice = Number(data?.data?.market_data?.current_price?.usd);

        if (lastPrice > currentPrice) {
          setPriceStyle('price-down');
        } else if (lastPrice < currentPrice) {
          setPriceStyle('price-up');
        } else if (lastPrice === currentPrice) {
          setPriceStyle('primary-color');
        }
        setVotes(data?.votes || 0);
        setCoin(data);
      });
    }
    return () => {
      socket.off(`coin:updated:${todayGame.id}:${coinId}`);
    };
  }, [socket, coinId, todayGame]);

  if (!isYesterday) {
    chosenMemeCoin = chosenMemeCoins.find((c) => c.gameId === todayGame.id);
    chosenYesterdayMemeCoin = chosenYesterdayMemeCoins.find((c) => c.gameId === todayGame.id);
  } else {
    chosenMemeCoin = chosenMemeCoins.find((c) => c.gameId === todayGame.scheduleGameId.id);
    chosenYesterdayMemeCoin = chosenYesterdayMemeCoins.find((c) => c.gameId === todayGame.scheduleGameId.id);
  }

  const toggleChartModal = () => setChartModal((p) => !p);

  const percentageChangeYesterday = useMemo(() => coin?.priceChange || 0, [coin]);

  const numbersData = useMemo(() => {
    // eslint-disable-next-line no-shadow
    const currentPrice = Number(coin?.data?.market_data?.current_price?.usd);
    let _startData = Number(coin?.start?.price);
    const _endPrice = Number(coin?.end?.price);

    if (!_startData) {
      _startData = Number(
        (coin?.data?.market_data?.current_price?.usd || 0)
        - (coin?.data?.market_data?.price_change_24h_in_currency?.usd || 0),
      );
    }

    const _currentPrice = Number(currentPrice || 0);
    const _diff = _currentPrice - _startData;

    return {
      marketCap: renderNumber(coin?.data?.market_data?.market_cap?.usd || 0),
      currentPrice: renderNumber(+_currentPrice),
      startPrice: renderNumber(_startData),
      endPrice: renderNumber(_endPrice),
      totalVolume: renderNumber(coin?.data?.market_data?.total_volume?.usd || 0),
      priceChangePercentage24h: isYesterday ? percentageChangeYesterday : Number((_diff / _startData) * 100).toFixed(2),
      twitterScreen: coin?.data?.links?.twitter_screen_name,
    };
  }, [coin, percentageChangeYesterday]);

  const isGrowing = useMemo(() => numbersData.priceChangePercentage24h > 0, [numbersData]);

  const renderChart = () => (
    <div className="flex flex-column meme-coins-chart-cont">
      <img
        className="mb-2 coin-img"
        alt={`${coin?.name}`}
        src={coin?.data?.image?.large || coin?.image}
      />

      <div style={{ height: 500 }}>
        <TradingViewWidget solanaId={coin?.solanaId} />
      </div>
    </div>
  );

  const getConfigImage = () => {
    if (!isYesterday && chosenMemeCoin?.id === coin?.id) {
      return {
        bgImage: "url('/images/match-card-bg-picked.png')",
        iconName: 'Chart',
        className: 'picked-card',
        picked: true,
      };
    }

    if (isYesterday) {
      if (coin.winner) {
        return {
          bgImage: 'url(/images/match-card-bg-gold.png)',
          iconName: !isMobile ? 'ChartBlack' : 'Chart',
          className: !isMobile ? 'gold-coin' : '',
          picked: chosenYesterdayMemeCoin?.memeCoinsId === coin?.id,
        };
      }

      if (chosenYesterdayMemeCoin?.memeCoinsId === coin?.id) {
        return {
          bgImage: "url('/images/match-card-bg-picked.png')",
          iconName: 'Chart',
          className: '',
          picked: true,
        };
      }
    }

    return {
      bgImage: "url('/images/match-card-bg.png')",
      picked: false,
      iconName: 'Chart',
      className: '',
    };
  };

  const renderData = (isModal = false) => (
    <>
      {isYesterday && isModal ? (
        <>
          <div className="data-cont">
            <span className="label">MG Coins Played:</span>
            <span className="value">
              {renderNumber(
                chosenYesterdayMemeCoin && chosenYesterdayMemeCoin.memeCoinsId === coin?.id
                  ? chosenYesterdayMemeCoin?.mgCoins
                  : 0,
              )}
            </span>
          </div>
          {chosenYesterdayMemeCoin
            && chosenYesterdayMemeCoin.memeCoinsId === coin?.id
            ? (
              <>
                <div className="data-cont">
                  <span className="label">My % Change:</span>
                  {renderPercentage(
                    chosenYesterdayMemeCoin.priceChange ? chosenYesterdayMemeCoin.priceChange : 0,
                    true,
                  )}
                </div>
                <div className="data-cont">
                  <span className="label">Time of pick:</span>
                  <span className="value">
                    {chosenYesterdayMemeCoin.lastVotedAt ? (
                      `${moment(chosenYesterdayMemeCoin.lastVotedAt).utc().format('HH:mm')} GMT`
                    ) : 0}
                  </span>
                </div>
                <div className="data-cont">
                  <span className="label">Price at time of pick:</span>
                  <span className="value">
                    <span className="dollar">$</span>
                    {`${chosenYesterdayMemeCoin.lastVotedAt ? chosenYesterdayMemeCoin.currentPrice : 0}`}
                  </span>
                </div>
              </>
            ) : null}
        </>
      ) : null}

      <div className="data-cont">
        <span className="label">Start price:</span>
        <span className="value">
          <span className="dollar">$</span>
          <Price value={numbersData.startPrice} />
        </span>
      </div>

      {isYesterday && isModal ? (
        <div className="data-cont">
          <span className="label">End price:</span>
          <span className="value">
            <span className="dollar">$</span>
            <Price value={coin.end.price} />
          </span>
        </div>
      ) : null}

      <div className="data-cont">
        <span className="label">{isYesterday ? 'End Price:' : 'Current price:'}</span>
        <span className="value">
          <span className="dollar">$</span>
          <Price value={numbersData.currentPrice} />
        </span>
      </div>

      {(!isYesterday) && isModal ? (
        <div className="data-cont">
          <span className="label">24h volume:</span>
          <span className="value">
            <span className="dollar">$</span>
            {`${numbersData.totalVolume}`}
          </span>
        </div>
      ) : null}

      {(!isYesterday) && isModal ? (
        <div className="data-cont">
          <span className="label">MarketCap:</span>
          <span className="value">
            <span className="dollar">$</span>
            {`${numbersData.marketCap}`}
          </span>
        </div>
      ) : null}

      {!isYesterday ? chosenMemeCoin?.id === coin?.id ? (
        <>
          {isModal ? (
            <div className="data-cont">
              <span className="label">Coins Played:</span>
              <span className="value">
                {renderNumber(chosenMemeCoin?.mgCoins)}
              </span>
            </div>
          ) : null}
          <div className="data-cont">
            <span className="label">Coin Picked At:</span>
            <span className="value">
              {moment(chosenMemeCoin?.lastVotedAt).utc().format('HH:mm')}
              {' '}
              GMT
            </span>
          </div>
        </>
      ) : null : chosenYesterdayMemeCoin?.id === coin?.id ? (
        <>
          {isModal ? (
            <div className="data-cont">
              <span className="label">Coins Played:</span>
              <span className="value">
                {renderNumber(chosenYesterdayMemeCoin?.mgCoins)}
              </span>
            </div>
          ) : null}
          <div className="data-cont">
            <span className="label">Coin Picked At:</span>
            <span className="value">
              {moment(chosenYesterdayMemeCoin?.lastVotedAt).utc().format('HH:mm')}
              {' '}
              GMT
            </span>
          </div>
        </>
      ) : null}

      {isYesterday && isModal ? (
        <div className="data-cont">
          <span className="label">% Change</span>
          {renderPercentage(percentageChangeYesterday, true)}
        </div>
      ) : null}

      {(isYesterday) && isModal ? (
        <div className="data-cont">
          <span className="label">Total Gamers:</span>
          <span className="value">{renderNumber(coin.votes * 10 || 0)}</span>
        </div>
      ) : null}
    </>
  );

  const {
    bgImage, className, iconName, picked,
  } = getConfigImage();

  let gameCardClassName;
  if (isYesterday) {
    gameCardClassName = (isMobile && coin.winner) ? 'picked' : (!isMobile && picked ? 'picked' : '');
  } else {
    gameCardClassName = picked ? 'picked' : '';
  }

  const renderPlayButton = () => (
    <Button
      className="mt-6 mb-2 w-full pick-button"
      onClick={() => {
        onChooseMemeCoin(coin, todayGame.id);
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: {
          xl: 'row', lg: 'row', md: 'row', sm: 'row', xs: 'column',
        },
        gap: {
          xl: 2, lg: 2, md: 2, sm: 1, xs: '3px',
        },
      }}
      >
        <Icon className="pick-winner-button-icon" name="PickWinnerBlack" width={30} height={22} />
        <Box sx={{
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: {
            xl: 'row', lg: 'row', md: 'row', sm: 'row', xs: 'column',
          },
          gap: {
            xl: 2, lg: 2, md: 2, sm: 1, xs: 0,
          },

        }}
        >
          {picked ? 'Play Again' : 'Pick Winner'}
          (
          {todayGame?.price?.toLocaleString('en-us') || 0}
          )
        </Box>
      </Box>
    </Button>
  );

  return (
    <Grid
      xl={size}
      lg={size}
      md={size}
      sm={6}
      xs={6}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        pb: {
          xs: '20px', lg: '2px', md: '2px', sm: '2px',
        },
      }}
      item
      className={`game-coin-${index} ${gameCardClassName}`}
    >
      <div
        className={clsx(
          'flex-1 meme-coin meme-coin-data w-full meme-coin-card',
          !index ? 'first' : 'last',
          className,
        )}
        style={{
          backgroundImage: bgImage || 'url(/images/matchrd-bg-gold.png)',
        }}
      >

        <div className="w-full relative">
          <div className="card-head">
            <div className="bordered-cont">
              <h3>
                {['glek', 'bighead', 'admin', 'ej'].includes(username) && (<span style={{ fontSize: '11px', marginRight: '5px' }}>{votes}</span>)}
                {coin?.name}
              </h3>
            </div>
          </div>
        </div>
        <div className="match-card-body w-full">
          <div className="head w-full">
            <div className="flex head-gap mb-2">
              <div className="flex flex-column">

                <img
                  alt={`${coin?.name}`}
                  src={coin?.data?.image?.large || coin?.image}
                />
              </div>

              <div className="flex price-container">
                <div className="flex flex-column justify-center">
                  <h4 className={`${priceStyle} mr-2 mb-0 red`}>
                    $
                    <Price value={numbersData.currentPrice} />
                  </h4>
                </div>

                <div className="flex flex-column justify-center">
                  <div className="flex">
                    <CardChip isGrowing={isGrowing}>
                      {numbersData.priceChangePercentage24h}
                      %
                    </CardChip>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="banner-cont">
            <div
              className="banner"
              style={{
                backgroundImage: `url('${coin?.data?.image?.large || coin?.image})`,
              }}
            />
            <img alt="coin" src={coin?.data?.image?.large || coin?.image} />
          </div>

          <Typography className="coin-name-xs">
            {coin?.name}
          </Typography>

          <div className={clsx('mb-2', 'w-full', 'pl-3', 'pr-3')}>
            {renderData()}

            <div className="open-chart mt-7" onClick={toggleChartModal}>
              <div className="flex flex-column">
                <Icon name={iconName} width={16} height={12} />
              </div>
              <span>More Info</span>
            </div>

            {picked ? (
              <div className={`picked-cont ${!isYesterday ? 'picked-cont-today' : ''}`}>
                <span>My Pick</span>
              </div>
            ) : null}
          </div>

          {!isYesterday && (
            <div className="w-full pick-button-container">
              {picked && (
                renderPlayButton()
              )}
            </div>
          )}

          {picked && <LockButton game={todayGame} currentVote={currentVote} />}

        </div>
      </div>

      {!isYesterday && (
        <div className="w-full pr-4 pl-4 pick-button-container">
          {!chosenMemeCoin && (
            renderPlayButton()
          )}
        </div>
      )}

      {(isYesterday && coin.winner && picked) && (
        <div className={`redeem-container-button${!index ? '' : ' '}`}>
          <RedeemButton totalTokens={userTokens} buttonSize={isMobile ? 'sm' : 'md'} />
        </div>
      )}

      <Modal
        title={`${coin?.name} Info`}
        open={chartModal}
        titleStyle={{ px: '80px', textAlign: 'center' }}
        handleClose={toggleChartModal}
        width="1000px"
      >
        <div className="modal-data">
          <div className="grid-cont">
            {renderData(true)}
          </div>

          {renderChart()}

          <AIData coin={coin} />
        </div>
      </Modal>
    </Grid>
  );
}
