import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import Button from '../../../../components/Button';

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: () => 'first-column',
  renderCell: ({ value }) => value,
};

export const columnsConfig = (t, submitRenew, onTransferPasses) => [
  {
    field: 'Type',
    minWidth: 300,
    flex: 1,
    headerName: t('Pack'),
    ...defaultConfig,
  },
  {
    field: 'Token',
    headerName: t('Code'),
    minWidth: 200,
    ...defaultConfig,
    renderCell: ({ row }) => (
      <Typography className="primary-color">
        {row.key || row.Token}
      </Typography>
    ),
    flex: 1,
  },
  {
    field: 'DateCreated',
    headerName: t('Created'),
    flex: 1,
    minWidth: 120,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('DD MMM YYYY'),
  },
  {
    field: 'DateUsed',
    headerName: t('Used'),
    minWidth: 120,
    flex: 1,
    ...defaultConfig,
    renderCell: ({ value }) => (value ? dayjs(value).format('DD MMM YYYY') : 'Not Used'),
  },
  {
    field: 'Used',
    headerName: t('Used For'),
    minWidth: 150,
    flex: 1,
    ...defaultConfig,
    renderCell: ({ value }) => (value ? 'Activation' : 'Not Used'),
  },
  {
    field: 'Transferable',
    headerName: t('Transferable'),
    minWidth: 140,
    flex: 1,
    ...defaultConfig,
    renderCell: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    field: 'action',
    headerName: '',
    minWidth: 231,
    flex: 1,
    ...defaultConfig,
    renderCell: (row) => {
      if (row.row.Used) {
        return '';
      }

      return (
        <div className="flex">
          <Button onClick={() => submitRenew({ pass: row.row.key || row.row.Token })} size="sm" className="mr-2 all-upper">
            Activate
          </Button>
          {row?.row?.Transferable ? (
            <Button bordered onClick={() => onTransferPasses({ pass: row.row.key || row.row.Token })} size="sm" className="all-upper">
              Transfer
            </Button>
          ) : null}
        </div>
      );
    },
  },
];

export default columnsConfig;
