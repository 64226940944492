import { Card, CardMedia } from '@mui/material';

const HostedVideo = ({ sourceUrl }) => (
  <Card
    sx={{
      maxWidth: '100%',
      width: '100%',
      margin: '0',
      height: 'auto',
    }}
  >
    <CardMedia
      height="100%"
      component="video"
      src={sourceUrl}
      title="Welcome Video"
      controls
      allowFullScreen
      autoPlay
      controlsList="nodownload noremoteplayback"
    />
  </Card>
);

export default HostedVideo;
