import { Grid } from '@mui/material';
import usePasses from './hook';
import PassCard from './components/passCard';
import PassModal from './components/modal';
import Button from '../../../components/Button';
import TextInput from '../../../components/Inputs/TextInput';
import AppLoader from '../../../components/AppLoader';
import Layout from '../../../components/CardLayout';

export default function Passes() {
  const {
    passes = [],
    errors,
    onPassClick,
    submit,
    selectedPass,
    handleModalClose,
    setSearchText,
    searchText,
    searchClick,
    loading,
    message,
    formLoading,
  } = usePasses();

  if (loading) {
    return <AppLoader />;
  }

  return (
    <Layout maxWidth="xl" cardStyle={{ padding: 0 }}>
      <Grid container columnSpacing={3}>
        <Grid item xl={10} lg={10} md={10} sm={9} xs={12} sx={{ mt: { xs: 3, sm: 0 } }}>
          <TextInput
            size="sm"
            placeholder="Lookup Key History"
            value={searchText}
            bordered
            onChange={(e) => {
              setSearchText(e);
            }}
            endAdornment={(
              <Button onClick={searchClick} className="py-auto my-auto mr-5" bordered size="sm">
                <span className="no-wrap">Lookup</span>
              </Button>
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={3} xs={12} className="flex justify-end" sx={{ mt: { xs: 3, sm: 0 } }}>
          <Button className="my-auto py-auto" size="sm" onClick={() => onPassClick()}>
            Add +
          </Button>
        </Grid>
      </Grid>
      {passes?.map((item, idx) => {
        if (item) {
          return (
            <Grid container key={idx} padding="60px 0">
              <PassCard key={item.Token} data={item} />
            </Grid>
          );
        }
        return '';
      })}
      <PassModal
        errors={errors}
        submit={submit}
        data={selectedPass}
        handleModalClose={handleModalClose}
        message={message}
        formLoading={formLoading}
      />
    </Layout>
  );
}
