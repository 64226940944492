import { useState } from 'react';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import Form from '../../../components/Form';
import { validateAll } from '../../../components/Form/config';
import { useNonAuthApi } from '../../../utils/api';

const formConfig = [
  {
    name: 'name',
    label: 'Full Name',
    placeholder: 'Full Name',
    type: 'text',
  },
  {
    name: 'email',
    label: 'Email',
    placeholder: 'Email',
    type: 'text',
  },
  {
    name: 'phone',
    label: 'Phone Number',
    placeholder: 'Phone Number',
    type: 'phone',
  },
];

const formValidationConfig = {
  name: { isEmpty: false, type: 'String', message: 'Required Field' },
  email: { isEmpty: false, type: 'String', message: 'Required Field' },
  phone: { isEmpty: false, type: 'String', message: 'Required Field' },
};

const WebinarForm = ({ handleCloseModal }) => {
  const { nonAuthPost } = useNonAuthApi();
  const [errors, setErrors] = useState(null);
  const [processing, setProcessing] = useState(false);

  const onFormSubmit = async (formData) => {
    setProcessing(true);
    const formErrors = validateAll(formData, formValidationConfig);
    setErrors();

    if (formErrors) {
      setErrors(formErrors);
      setProcessing(false);
      return;
    }

    try {
      const webinarUrl = process.env.REACT_APP_WEBINAR_URL;
      const response = await nonAuthPost('/gamification-webinar', { data: formData });

      if (response) {
        window.open(webinarUrl, '_blank');
      }

      handleCloseModal();
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
    setProcessing(false);
  };

  return (
    <div className="relative">
      {processing && (
        <div
          className="flex justify-center"
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            background: 'transparent',
            zIndex: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Form
        config={formConfig}
        size="sm"
        submitLabel="Submit"
        onSubmit={onFormSubmit}
        buttonType="button"
        buttonStyle={{ width: '100%', marginTop: 14 }}
        errors={errors}
      />
    </div>
  );
};

export default WebinarForm;
