import { createContext } from 'react';
import usePendingRedeemApi from './api';

export const GlobalBannerContext = createContext({
  t: () => {},
  user: {},

  allBanners: [],
  loadedAllBanners: false,
  bannerData: {},
  bannerToDelete: {},
  formSubmitting: false,
  formErrors: [],
  setFormErrors: () => {},
  handleFormSubmit: () => {},
  handleDeleteSubmit: () => {},
  handleOpenModal: () => {},
  handleCloseModal: () => {},
  handleCloseDeleteModal: () => {},
  handleOpenModalToEdit: () => {},
  handleOpenModalToDelete: () => {},

  bannerFormConfiguration: [],
});

export const GlobalBannerProvider = ({ children }) => {
  const value = usePendingRedeemApi();

  return (
    <GlobalBannerContext.Provider value={value}>
      {children}
    </GlobalBannerContext.Provider>
  );
};
