import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useNonAuthApi } from '../../utils/api';
import LandingPage from '../LandingPage';

const FreeTrialPage = () => {
  const navigate = useNavigate();
  const { nonAuthGet } = useNonAuthApi();

  const fetchCurrentAmbassador = async () => {
    try {
      const response = await nonAuthGet('/booster-program');
      sessionStorage.setItem('signupParent', JSON.stringify(response));
      sessionStorage.setItem('boosterParentSID', response.SID);
    } catch (err) {
      console.log(err);
      toast.error(err);
    }

    navigate('/onboarding');
  };

  useEffect(() => {
    fetchCurrentAmbassador();
  }, []);

  return (
    <div className="free-trial">
      <LandingPage />
    </div>
  );
};

export default FreeTrialPage;
