import {
  useContext, useEffect, useState, 
} from 'react';
import { Grid, Typography } from '@mui/material';
import ComponentLoader from '../../../../components/ComponentLoader';
import { AdminReportContext } from '../../context';
import Layout from '../../../../components/CardLayout';
import Table from '../../../../components/Table';

const GameSingleResult = () => {
  const { gameSingleResultData, gameSingleResultColumnsConfiguration } = useContext(AdminReportContext);
  const [tableData, setTableData] = useState([]);
  const [tableDataLoaded, setTableDataLoading] = useState(false);

  useEffect(() => {
    setTableDataLoading(false);
    
    if (gameSingleResultData) {
      const coin1 = gameSingleResultData?.coinOne;
      const coin2 = gameSingleResultData?.coinTwo;
      
      const dataVotes = gameSingleResultData.votes.map((data) => {
        data.id = data._id;
        data.memeCoin = data.memeCoinsId === coin1.id ? coin1 : coin2;
        data.winner = data.memeCoinsId === gameSingleResultData.winningCoin;

        return data;
      });
      
      setTableData(dataVotes);

      setTableDataLoading(true);
    }
  }, [gameSingleResultData]);

  return (
    <Layout maxWidth="xl" className={`game-single-result${tableDataLoaded ? '' : ' d-none'}`}>
      {tableDataLoaded ? (
        <>
          <Typography fontSize="24px" textAlign="center" fontWeight="700">
            User Votes
          </Typography>

          <div className="result-match">
            <div
              className={`result-match-one${
                gameSingleResultData.winningCoin === gameSingleResultData.coin1
                  ? ' winner'
                  : ''
              }`}
            >
              <picture>
                <img
                  src={gameSingleResultData?.coinOne?.image}
                  alt={gameSingleResultData?.coinOne?.name}
                />
              </picture>
              {gameSingleResultData?.coinOne?.name}
            </div>
            <span>VS</span>
            <div
              className={`result-match-two${
                gameSingleResultData.winningCoin === gameSingleResultData.coin2
                  ? ' winner'
                  : ''
              }`}
            >
              <picture>
                <img
                  src={gameSingleResultData?.coinTwo?.image}
                  alt={gameSingleResultData?.coinTwo?.name}
                />
              </picture>
              {gameSingleResultData?.coinTwo?.name}
            </div>
          </div>

          {tableDataLoaded ? (
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                mt: 6,
              }}
            >
              <Table
                loading={!tableDataLoaded}
                columns={gameSingleResultColumnsConfiguration}
                rows={tableData}
                className="table"
                showPagination
              />
            </Grid>
          ) : (
            <ComponentLoader />
          )}
        </>
      ) : (<ComponentLoader />)}
    </Layout>
  );
};

export default GameSingleResult;
