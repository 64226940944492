import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import useRenewMembership from './hook';
import TextInput from '../../../../components/Inputs/TextInput';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import MembershipsTab from '../MembershipsTab';
import PurchasePasses from '../PurchasePasses';
import PurchasePackages from '../PurchasePackages';
import InfoBubbleModal from '../../../../components/InfoBubbleModal';

export default function RenewPasses({ refresh }) {
  const { t } = useTranslation();
  const {
    token, setToken, submitRenew, tabsConfig,
    purchaseModalVisible, setPurchaseModalVisible,
  } = useRenewMembership(refresh);

  return (
    <div className="renew-passes">
      <div className="flex flex-1 activate-cont">
        <div className="flex flex-column justify-center">
          <div className="flex activate-coin-text justify-center">
            <h2 className="primary-color">
              {t('Activate Coin Pack')}
            </h2>
            <div className="icon-cont">
              <InfoBubbleModal iconColor="var(--primary-color)">
                <Box>
                  <Typography sx={{ color: 'var(--primary-color)' }}>
                    {t('Activate Coin Pack')}
                  </Typography>
                  <Typography>
                    {t(`Copy/paste the token ID (i.e. PP-7890C123) of any unused coin
                    pack to activate the pack and receive the coins on your account.`)}
                  </Typography>
                </Box>
              </InfoBubbleModal>
            </div>
          </div>
        </div>

        <div className="flex flex-column justify-center">
          <h5>
            {t('Copy in any unused coin pack')}
          </h5>
        </div>

        <div className="flex flex-column justify-center flex-1">
          <TextInput
            size="sm"
            value={token}
            placeholder="enter a pack"
            onChange={(v) => setToken(v)}
            endAdornment={(
              <div className="p-1 flex">
                <Button
                  onClick={submitRenew}
                  className="py-auto my-auto mr-1"
                  bordered
                  size="sm"
                >
                  <span className="no-wrap">ACTIVATE</span>
                </Button>
              </div>
            )}
          />
        </div>
      </div>

      <div className="flex purchase-cont">
        <div className="flex purchase-coin-text justify-center">
          <h2 className="primary-color">
            {t('Purchase Packs / Bundles')}
          </h2>
          <div className="icon-cont">
            <InfoBubbleModal iconColor="var(--primary-color)">
              <Box>
                <Typography sx={{ color: 'var(--primary-color)' }}>
                  {t('Purchase Packs / Bundles')}
                </Typography>
                <Typography>
                  {t(`Purchase new coin packs and bundles. You may choose to instantly 
                  activate them on your 
                  account, or transfer them to your friends or Ambassador team.`)}
                </Typography>
              </Box>
            </InfoBubbleModal>
          </div>
        </div>
        <div className="flex justify-center">
          <Button className="purchase-btn" onClick={() => setPurchaseModalVisible(true)}>
            Purchase
          </Button>
        </div>
      </div>

      <Modal
        width={550}
        open={purchaseModalVisible}
        handleClose={() => setPurchaseModalVisible(false)}
      >
        <Grid item xs={12} marginBottom={6}>
          <PurchasePasses reset={refresh} />
        </Grid>

        <Grid item xs={12}>
          <PurchasePackages reset={refresh} />
        </Grid>
      </Modal>
    </div>
  );
}
