/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

export default function Node({ username, displayName, dateExpiring }) {
  return (
    <div style={{ position: 'relative' }}>
      <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }}>{username}</Typography>
      <Typography sx={{ fontSize: '12px', color: 'gray' }}>{displayName}</Typography>
      <Typography sx={{ fontSize: '12px', color: 'gray' }}>
        {`${dayjs(dateExpiring).tz('UTC').format('MMM DD, YYYY')}`}
      </Typography>
    </div>
  );
}
