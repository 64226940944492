import { PersonRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { renderNumber } from '../../../../utils/string';

const UserAccordionSummary = ({
  image, name, expiration, personalVolume, 
}) => {
  const getExpirationClass = () => {
    if (!expiration) {
      return '';
    }

    const today = dayjs();
    const expirationDays = dayjs(expiration).diff(today, 'day');
    let expirationClass = expirationDays <= 7 ? ' expiring' : '';
    expirationClass = expirationDays <= 0 ? ' expired' : expirationClass;

    return expirationClass;
  };

  return (
    <div className="user-accordion-summary w-full">
      <div className="image">
        {!image ? (
          <PersonRounded fontSize="large" />
        ) : (
          <img
            src={image}
            alt="Profile pic"
            className="profile-pic"
            height="44"
          />
        )}
      </div>
      <div className="info">
        <div className="name">
          <Typography className={`date-expiration${getExpirationClass()}`}>
            {!expiration ? '' : dayjs(expiration).format('MMMM DD, YYYY')}
          </Typography>
          <h3>{name}</h3>
        </div>
        {Number(personalVolume) > 0 && (
          <div className="personal-volume">
            <Typography>
              Personal Volume
            </Typography>
            <h3>{renderNumber(personalVolume)}</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAccordionSummary;
