import { createContext } from 'react';
import usePodcast from './hook';

export const PodcastContext = createContext({
  t: () => {},
  user: {},

  getPodcastsData: () => {},

  podcastsData: {},
  podcastsDataLoaded: {},

  paginationData: {},
});

export const PodcastProvider = ({ children }) => {
  const value = usePodcast();
  return <PodcastContext.Provider value={value}>{ children }</PodcastContext.Provider>;
};
