import { useNavigate } from 'react-router-dom';
import {
  Container, Grid, Typography, 
} from '@mui/material';
import { useContext } from 'react';
import Layout from '../../../components/CardLayout';
import ComponentLoader from '../../../components/ComponentLoader';
import { BoosterLeadAdminContext, BoosterLeadAdminProvider } from './context';
import Table from '../../../components/Table';
import Button from '../../../components/Button';
import AddAmbassadorModal from './components/AddAmbassadorModal';

const BoosterLeadAdmin = () => {
  const { navigate } = useNavigate();
  const {
    t,
    user,

    turnsDataColumnsConfiguration,
    turnsData,
    loadedTurnsData,

    setOpenAddAmbassadorModal,
  } = useContext(BoosterLeadAdminContext);

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container className="admin-booster-lead">
      <Layout
        maxWidth="100%"
        sx={{
          mt: 6,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            width: '100%',
          }}
        >
          {t('Booster Lead Admin')}
        </Typography>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingTop: '14px' }}>
          <Button size="sm" onClick={() => setOpenAddAmbassadorModal(true)}>
            <span>Add Ambassador</span>
          </Button>
        </Grid>
      </Layout>

      <AddAmbassadorModal />

      <Grid
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        {loadedTurnsData ? (
          <Table
            loading={!loadedTurnsData}
            columns={turnsDataColumnsConfiguration}
            rows={turnsData}
            className="table mb-4"
          />
        ) : (
          <div className="flex justify-center">
            <ComponentLoader />
          </div>
        )}
      </Grid>
    </Container>
  );
};

export default () => (
  <BoosterLeadAdminProvider>
    <BoosterLeadAdmin />
  </BoosterLeadAdminProvider>
);
