import {
  useCallback, useContext, useEffect, useMemo, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import { defaultTurnsColumnsConfig } from '../config';

const useBoosterLeadAdminApi = () => {
  const { authGet, authPost, authDelete } = useApi();

  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const [turnsData, setTurnsData] = useState([]);
  const [loadedTurnsData, setLoadedTurnsData] = useState(false);
  const [openAddAmbassadorModal, setOpenAddAmbassadorModal] = useState(false);
  const [ambassadorsData, setAmbassadorsData] = useState([]);
  const [loadedAmbassadorsData, setLoadedAmbassadorsData] = useState(false);
  const [selectedAmbassador, setSelectedAmbassador] = useState(null);

  const fetchAllTurnsData = useCallback(
    async () => {
      // setLoadedTurnsData(false);
      const allTurnsData = await authGet('/booster-program/all-turns');
      
      setTurnsData(allTurnsData);

      setLoadedTurnsData(true);
    },
    [authGet],
  );

  const handleAddAmbassadorModalSubmit = useCallback(
    async () => {
      if (!selectedAmbassador) {
        toast.error('You must select an Ambassador.');
        return;
      }

      const existing = turnsData.find((turn) => turn.ambassadorId === selectedAmbassador.SID);

      if (existing) {
        toast.error('Duplicated user.');
        setSelectedAmbassador(null);
        return;
      }

      try {
        await authPost('/booster-program/add-turn', { data: { ambassadorId: selectedAmbassador.SID } });

        setOpenAddAmbassadorModal(false);

        fetchAllTurnsData();

        toast.success('Added new Turn.');
      } catch (err) {
        toast.error('Error.');
      }

      setSelectedAmbassador(null);
    },
    [authPost, selectedAmbassador],
  );

  const handleCloseAmbassadorModal = useCallback(
    () => {
      setSelectedAmbassador(null);
      setLoadedAmbassadorsData(false);
      setOpenAddAmbassadorModal(false);
      setAmbassadorsData([]);
    },
    [
      setSelectedAmbassador,
      setLoadedAmbassadorsData,
      setAmbassadorsData,
      setOpenAddAmbassadorModal,
    ],
  );

  const fetchAmbassadors = useCallback(
    async ({ search }) => {
      setLoadedAmbassadorsData(false);
      const response = await authGet('/booster-program/ambassadors', {
        params: { search },
      });
      
      setAmbassadorsData(response.data);

      setLoadedAmbassadorsData(true);
    },
    [authGet],
  );

  const clearAmbassadorsData = useCallback(
    async () => {
      setAmbassadorsData([]);
      setLoadedAmbassadorsData(false);
    },
    [setAmbassadorsData, setLoadedAmbassadorsData],
  );

  const handleChangeSelectedAmbassador = useCallback(
    async (userId) => {
      setSelectedAmbassador(userId);
      setAmbassadorsData([]);
      setLoadedAmbassadorsData(false);
    },
    [setAmbassadorsData, setLoadedAmbassadorsData],
  );

  const handleChangeTurnOrder = useCallback(
    async ({ ambassadorId, moveForward = true }) => {
      try {
        await authPost('/booster-program/reorder', { data: { ambassadorId, moveForward } });
        fetchAllTurnsData();
        toast.success(`Turn Order moved ${moveForward ? 'down' : 'up'} successfully.`);
      } catch (err) {
        toast.error('Error moving the Turn Order Number');
        console.log(err);
      }
    },
    [authPost],
  );

  const handleDeleteTurn = useCallback(
    async ({ ambassadorId }) => {
      try {
        await authDelete(`/booster-program/delete-turn/${ambassadorId}`);
        fetchAllTurnsData();
        toast.success(`Deleted user ${ambassadorId}`);
      } catch (err) {
        toast.error('Error moving the Turn Order Number');
        console.log(err);
      }
    },
    [authDelete],
  );

  useEffect(() => {
    fetchAllTurnsData();
  }, []);

  // Columns Configuration ===================================
  const turnsDataColumnsConfiguration = useMemo(
    () => defaultTurnsColumnsConfig(t, handleChangeTurnOrder, handleDeleteTurn),
    [t, setOpenAddAmbassadorModal],
  );
  // Columns Configuration ===================================

  return {
    t,
    user,

    fetchAllTurnsData,
    turnsDataColumnsConfiguration,
    turnsData,
    loadedTurnsData,
    
    openAddAmbassadorModal,
    handleAddAmbassadorModalSubmit,
    handleCloseAmbassadorModal,

    fetchAmbassadors,
    clearAmbassadorsData,
    setOpenAddAmbassadorModal,
    handleChangeSelectedAmbassador,
    selectedAmbassador,
    ambassadorsData,
    loadedAmbassadorsData,
  };
};

export default useBoosterLeadAdminApi;
