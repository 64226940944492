/* eslint-disable jsx-a11y/media-has-caption */
import { Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import Icon from '../Icon';

const PlayPodcast = ({ title = 'Listen to the MemeGames Podcast!', url = null, setAudioMetadata = null }) => {
  const podcastUrl = url || process.env.REACT_APP_PODCAST_AUDIO_URL;
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackProgress, setTrackProgress] = useState(0);

  const audioRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  const calculateProgress = (currentTime, audioDuration) => {
    const progress = Math.floor((currentTime / audioDuration) * 100);

    if (setAudioMetadata) {
      setAudioMetadata({
        duration: audioDuration,
        elapsed: currentTime,
      });
    }

    setTrackProgress(progress);
  };

  const saveAudioMetadata = (audioDuration) => {
    if (setAudioMetadata) {
      setAudioMetadata({
        duration: audioDuration,
      });
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    const onTimeUpdate = () => calculateProgress(audio.currentTime, audio.duration);
    const onLoadedMetadata = () => saveAudioMetadata(audio.duration);

    audio.addEventListener('timeupdate', onTimeUpdate);
    audio.addEventListener('loadedmetadata', onLoadedMetadata);

    return () => {
      audio.addEventListener('timeupdate', onTimeUpdate);
      audio.addEventListener('loadedmetadata', onLoadedMetadata);
    };
  }, []);

  return (
    <Button
      size="sm"
      className="podcast-button"
      style={{ width: 'fit-content', marginTop: '24px', position: 'relative' }}
      onClick={handlePlayPause}
      icon={<Icon name={isPlaying ? 'Pause' : 'Play'} width={17} height={17} />}
    >
      <Typography sx={{ mx: '15px' }}>
        {title}
      </Typography>
      <audio ref={audioRef} src={podcastUrl} preload="metadata" loop />
      <span className="audio-progress" style={{ width: `${trackProgress}%` }} />
    </Button>
  );
};

export default PlayPodcast;
