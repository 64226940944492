/* eslint-disable jsx-a11y/media-has-caption */
import { Grid, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext, useState } from 'react';
import LoopIcon from '@mui/icons-material/Loop';
import PlayPodcast from '../../../../components/PlayPodcast';
import { AdminPodcastContext } from '../context';
import { renderHourFromSeconds } from '../../../../utils/string';

const PodcastRow = ({ podcast }) => {
  const { t, handleOpenModalToEdit, handleOpenDeleteModal } = useContext(AdminPodcastContext);

  const [audioMetaData, setAudioMetadata] = useState(null);

  return (
    <Grid
      container
      borderBottom="1px solid rgba(255, 255, 255, 0.1)"
      paddingY="18px"
    >
      <Grid item xs={12} lg={3} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
        <Typography
          fontSize="8px"
          fontWeight="600"
          color="rgba(255, 255, 255, 0.7)"
        >
          {t('Title')}
        </Typography>
        <Typography fontSize="14px">{podcast?.title}</Typography>
      </Grid>
      <Grid item xs={12} lg={3} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
        <Typography
          fontSize="8px"
          fontWeight="600"
          color="rgba(255, 255, 255, 0.7)"
        >
          {t('Description')}
        </Typography>
        <Typography fontSize="14px">{podcast?.description}</Typography>
      </Grid>
      <Grid item xs={12} lg={1} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
        <Typography
          fontSize="8px"
          fontWeight="600"
          color="rgba(255, 255, 255, 0.7)"
        >
          {t('Duration')}
        </Typography>
        <Typography fontSize="14px">
          {audioMetaData ? (
            <>
              {audioMetaData.elapsed > 0 && (
                <span>
                  {renderHourFromSeconds(audioMetaData.elapsed)}
                  {' '}
                  /
                  {' '}
                </span>
              )}
              <span>{renderHourFromSeconds(audioMetaData.duration || 0)}</span>
            </>
          ) : (
            <LoopIcon color="primary" className="loading-icon" />
          )}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={10}
        lg={4}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: { xs: 2, lg: 0 },
          paddingRight: { md: '24px' },
        }}
      >
        <PlayPodcast
          title="Listen to the Podcast!"
          url={podcast?.url}
          setAudioMetadata={setAudioMetadata}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        lg={1}
        gap={4}
        display="flex"
        sx={{
          justifyContent: { xs: 'center', lg: 'flex-end' },
          paddingTop: { xs: 2, lg: 0 },
          alignItems: 'center',
        }}
      >
        <EditIcon
          sx={{ cursor: 'pointer', display: 'block' }}
          color="primary"
          onClick={() => { handleOpenModalToEdit(podcast); }}
        />
        <DeleteIcon
          sx={{ cursor: 'pointer', display: 'block' }}
          color="primary"
          onClick={() => { handleOpenDeleteModal(podcast); }}
        />
      </Grid>
    </Grid>
  );
};

export default PodcastRow;
